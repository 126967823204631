/*body {
    margin: 0;
    padding: 0;
    background-color: #1d2634;
    color: #9e9ea4;
    font-family: 'Montserrat', sans-serif;
  }
  */
  .sidebar-profile-image-display-after {
    height: 100px !important;
    width: 100px !important;
    border-radius: 100%;
    cursor: pointer;
    margin-left: 35px;
    margin-top: 35px;
}
  .profile-image-in-header{
    width: 5%;
  }
  
  .userdashboard-icon {
    vertical-align: middle;
    margin-top: 5px;
    //line-height: 5px;
    font-size: 20px;
  }
  .userdashboard-icon_header {
    margin-left: 40px;
    vertical-align: middle;
    line-height: 1px;
    font-size: 50px;
    color: white;
  }
  .userdashboard-icon, .userdashboard-icon_header {
    margin-right: 5px;
  }
  
  .userdashboard-close_icon {
    color: red;
    margin-left: 30px;
    margin-top: 10px;
    cursor: pointer;
  }
  
  .grid-container {
    position: relative;
    //top: 80px;
    display: grid;
    grid-template-columns: 260px 1fr 1fr 1fr;
    grid-template-rows: 0.2fr 3fr;
    grid-template-areas:
      'sidebar header header header'
      'sidebar main main main'
      'sidebar enrolled-courses-main enrolled-courses-main enrolled-courses-main';
    height: 100vh;
  }
  
  /* Header  */
  .userdashboard-header {
    grid-area: header;
    height: 60px;
    display: flex;
    justify-content: space-between;
    padding: 0 30px 0 30px;
    box-shadow: 0 6px 7px -3px rgba(0, 0, 0, 0.35);
    align-items: center;
  }
  
  .userdashboard-menu-icon {
    display: none;
  }

  .userdashboard-header-right{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
    
  }
  .userdashboard-header-left{
    display: flex;
    flex-direction: row;
    align-items: center;
    //border: none;
  }
  .header-search{
    height: 60px;
    width: 400px;
    border-width: 1px;
    border-color: white;
    padding: 10px;
  }
  .userdashboard-home-icon{
    //font-size: 20px;
    width: 30px;
    font-weight: bold;
    cursor: pointer;
  }
  /* End Header  */
  
  /* Sidebar  */
  #userdashboard-sidebar {
    grid-area: sidebar;
    height: 100%;
    background-color: #263043;
    overflow-y: auto;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
  }
  
  .sidebar-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px 0px 30px;
    margin-bottom: 30px;
    color: white;
  }
  .sidebar-title-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px 0px 30px;
    margin-bottom: 30px;
    color: white;
  }
  
  .sidebar-title > span {
    display: none;
  }
  
  .sidebar-brand {
    margin-top: 15px;
    font-size: 20px;
    font-weight: 700;
    color: #ff6d00;
  }
  
  .sidebar-list {
    padding: 0;
    list-style-type: none;
  }
  
  .sidebar-list-item {
    display: flex;
    flex-direction: row;
    padding: 20px 20px 20px 20px;
    font-size: 18px;
  }

  .sidebar-list-item-title{
    color: white;
    font-size: 20px;
    margin-left: 5px;
  }
  
  .sidebar-list-item:hover {
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
  }
  
  .sidebar-list-item > a {
    text-decoration: none;
    color: #9e9ea4;
  }
  
  .sidebar-responsive {
    display: inline !important;
    position: absolute;
    /*
      we want the z-index of the sidebar higher so that
      the charts are not showing over the sidebar 
      on small screens
    */
    z-index: 12 !important;
  }
  
  /* End Sidebar  */
  
  
  /* Main  */  
  .main-container {
    grid-area: main;
    overflow-y: auto;
    padding: 20px 20px;
    color: rgba(255, 255, 255, 0.95);
  }
  
  .main-title {
    display: flex;
    justify-content: space-between;
  }
  
  .main-cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    margin: 15px 0;
  }
  
  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 8px 15px;
    border-radius: 5px;
  }
  
  .card:first-child {
    background-color: #2962ff;
  }
  
  .card:nth-child(2) {
    background-color: #ff6d00;
  }
  
  .card:nth-child(3) {
    background-color: #2e7d32;
  }
  
  .card:nth-child(4) {
    background-color: #d50000;
  }
  
  .card-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .card-inner > .card_icon {
    font-size: 25px;
  }
  
  .charts {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 60px;
    height: 300px;
  }
  
  /* End Main  */
  
  
  /* Medium <= 992px */

  .userdashboard-profile-icons {
    width: 30px; /* Set a fixed width */
    height: 30px; /* Set a fixed height */
    border-radius: 50%; /* Makes the image circular */
    object-fit: cover; /* Ensures the image covers the circular area without stretching */
    border: 2px solid #fff; /* Optional: Add a border to the image */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for styling */
    cursor: pointer;
  }

  .userdashboard-profile-menu-list {
    position: absolute;
    background-color: #f4f1f1;
    width: 200px;
    display: flex;
    flex-direction: column;
    top: 80px;
    right: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 100;
  }
  .userdashboard-profile-menu-list li {
    cursor: pointer;
    padding: 10px;
    text-align: center;
    border-bottom: 1px solid #ddd;
  }
  
  .userdashboard-profile-menu-list li:last-child {
    border-bottom: none;
  }
  
  .userdashboard-profile-menu-list li:hover {
    background-color: #FBB03B;
  }
  .userdashboard-profile-menu-logout{
    background-color: red;
  }
    
  @media screen and (max-width: 992px) {
    .grid-container {
      grid-template-columns: 1fr;
      grid-template-rows: 0.2fr 3fr;
      grid-template-areas:
        'header'
        'main';
    }
  
    #userdashboard-sidebar {
      display: none;
    }
  
    .userdashboard-menu-icon {
      display: inline;
    }
    .userdashboard-home-icon{
      position: absolute;
      margin-left: 40px;
    }
  
    .sidebar-title > span {
      display: inline;
    }
    .charts {
      height: 800px;
    }
  }
  /* Small <= 768px */
  .header-search{
    height: 60px;
    width: 200px;
  }
    
  @media screen and (max-width: 760px) {
    .userdashboard-home-icon{
      margin-left: 0px;
    }
    .main-cards {
      grid-template-columns: 1fr;
      gap: 10px;
      margin-bottom: 0;
    }
  
    .charts {
      grid-template-columns: 1fr;
      margin-top: 30px;
    }
  }
  
  /* Extra Small <= 576px */
  
  @media screen and (max-width: 576px) {
    .userdashboard-header {
      gap: 10px;
    }
    .userdashboard-home-icon{
      position: relative;
    }
    .userdashboard-hedaer-left {
      display: none;
    }
    .header-search{
      width: 100px;
    }
  }