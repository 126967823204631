/* .course-card-box{
    border-radius: 20px;

} */
.enrolled-course-card-box{
    width: 350px;
    height: 450px; /* Fixed height for all cards */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px;
}

.enrolled-course-card-box Card{
    background-color: #ECE1E1;
}
.enrolled-enroll-button{
    background-color: #58C233;
    color: white;
    width: 89px;
    height: 30px;
    font-size: 15px;
    font-weight: bold;
    border-radius: 4px;
    padding: 3px;
}
.enrolled-play-button{
    background-color: #9533c2;
    color: white;
    width: 89px;
    height: 30px;
    font-size: 15px;
    font-weight: bold;
    border-radius: 4px;
    padding: 3px;
}
.enrolled-course-card-footer-content {
    background-color: #D9D9D9;
    width: auto;
    justify-items: center;
}

.enrolled-course-card-footer-content-title {
    font-size: 12px; /* Adjusted for better visibility */
    font-weight: bold;
    color: #58C233;
}

.enrolled-course-card-footer-content-fee {
    display: flex;
    flex-direction: column; /* Allow wrapping for fee and discount */
}

.enrolled-course-card-footer-content-fee-actual {
    font-size: 12px;
    font-weight: bold;
    color: #EA8208;
    text-decoration: line-through;
    gap: 2px;
    display: flex;
}

.enrolled-course-card-footer-content-fee-discount {
    font-size: 12px;
    font-weight: bold;
    color: #58C233; /* Discounted price */
    display: flex;
    gap: 2px;
}
.enrolled-course-card-image{
    height: 200px;
}

.enrolled-course-card-content{
    cursor: pointer;
    background-color: #ECE1E1;
    justify-content: space-between;
}

.enrolled-course-card-content-title{
    color: #1556FC;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.2;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.enrolled-course-card-content-topiclist{
    font-size: 12px;
    color: #555;
    margin-top: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.enrolled-course-card-review{
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    align-content: center;
}

.enrolled-course-card-review div1{
    display: flex;
    flex-direction: row;
    font-weight: 500;
}
.enrolled-course-card-review div11{
    display: flex;
    flex-direction: column;
    width: 60px;
    height: 16px;
}
.enrolled-course-card-review div12{
    display: flex;
    flex-direction: column;
    width: 4px;
    height: 15px;
    color: #EA8208;
    font-weight: bold;
    margin-left: 5px;
}
.enrolled-course-card-review div13{
    display: flex;
    flex-direction: column;
    width: 80px;
    height: 16px;
}
.enrolled-course-card-review div2{
    display: flex;
    flex-direction: row;
    gap: 2px;
    font-weight: 500;
}
.enrolled-course-card-review div21{
    color: #EA8208;
}

.enrolled-add-to-cart-button {
    margin-left: 10px;
    background-color: #1556FC;
    color: white;
    border-radius: 4px;
    padding: 5px 10px;
}

.enrolled-add-to-cart-button:hover {
    background-color: #0a3ab8;
}


/* @media screen and (max-width: 760px){
    .course-card-box{
        width: 300px;
    }
} */





@media screen and (max-width: 900px) {
    
    .enrolled-course-card-content-title {
        font-size: 14px;
    }
    .enrolled-discounted-price, .enrolled-original-price {
        font-size: 12px;
    }
    .enrolled-enroll-button, .enrolled-play-button {
        font-size: 12px;
    }
}

@media screen and (max-width: 600px) {
    .enrolled-course-card-box {
        width: 280px;
        height: 350px;
    }
    .enrolled-course-card {
        flex-direction: column;
    }
    .enrolled-course-card-image{
        height: 150px;
    }
    
    .enrolled-course-card-content-title{
        font-size: 10px;
    }
    .enrolled-course-card-content-topiclist{
        font-size: 6px;
        margin-top: 2px;
    }
    .enrolled-course-card-footer-content-title {
        font-size: 11px; /* Smaller font size on mobile */
    }
    .enrolled-course-card-footer-content-fee-actual,
    .enrolled-course-card-footer-content-fee-discount {
        font-size: 12px; /* Adjust size on small screens */
    }
    .enrolled-course-card-footer-content {
        padding: 0px; /* Reduced padding for mobile */
    }
    .enrolled-course-card-review div1{
        flex-direction: column;
        font-weight: 500;
    }
    .enrolled-course-card-review div12{
        display: none;
    }
}