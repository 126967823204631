.CoverImageText{
    position: absolute;
    top: 200px;
    left: 150px;
}
.CoverImageText-text1{
    font-family: 'Courier New', Courier, monospace;
    font-size: 20px;
    font-weight: bold;
}
.CoverImageText-text2{
    font-family: 'Courier New', Courier, monospace;
    font-size: 20px;
    font-weight: bold;
    color: #e98107;
}

@media only screen and (max-width: 960px){
    .CoverImageText{
        display: none;
    }
}
