/* 🌟 Global Fix for Overflow Issue */
html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    overflow-x: hidden; /* Prevents horizontal scrolling */
}

/* 🌟 Testimonials Section */
.testimonials {
    text-align: center;
    padding: 5rem 2rem;
    background: linear-gradient(135deg, #FBB03B 0%, #ff9a3b 100%);
    color: white;
    position: relative;
    overflow-x: hidden; /* Ensures no horizontal scrolling */
    max-width: 100vw; /* Ensures the section does not exceed viewport width */
    box-sizing: border-box; /* Fix padding issues */
}

/* 🏆 Heading */
.testimonials-heading {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 3rem;
    position: relative;
    z-index: 2;
}

/* 💬 Testimonial Card */
.testimonial-card {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(15px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 2.5rem;
    border-radius: 16px;
    width: 100%; /* Prevent overflow */
    max-width: 600px;
    margin: auto;
    text-align: center;
    position: relative;
    transition: all 0.3s ease-in-out;
    box-sizing: border-box; /* Fix padding issues */
}

/* 🌀 Hover Effects */
.testimonial-card:hover {
    transform: translateY(-5px);
    box-shadow: 0px 10px 30px rgba(255, 255, 255, 0.2);
}

/* ✨ Quote Icon */
.quote-icon {
    font-size: 3rem;
    opacity: 0.8;
}

/* 📝 Review Text */
.review-text {
    font-size: 1.4rem;
    font-style: italic;
    margin: 1.5rem 0;
}

/* 👤 Testimonial Info */
.testimonial-info {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: 1.5rem;
    flex-wrap: wrap; /* Prevents overflow */
    max-width: 100%; /* Ensures flexbox does not push outside */
}

/* 🖼️ Testimonial Image */
.testimonial-img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 3px solid white;
}

/* 📌 Role Styling */
.role {
    font-size: 1rem;
    font-weight: 300;
    opacity: 0.9;
}

/* ⭐ Star Ratings */
.rating {
    display: flex;
    justify-content: center;
    margin-top: 0.5rem;
}

.star-icon {
    color: gold;
    font-size: 1.2rem;
    margin: 0 2px;
}

/* ⏭️ Navigation Buttons */
.testimonial-nav {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 2rem;
    flex-wrap: wrap; /* Ensures buttons stay within the screen */
}

.nav-btn {
    background: white;
    color: #FBB03B;
    border: none;
    padding: 0.8rem 1.2rem;
    border-radius: 50%;
    font-size: 1.5rem;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.nav-btn:hover {
    background: gold;
    color: white;
    transform: scale(1.1);
}

/* 📱 Responsive Design */
@media (max-width: 768px) {
    .testimonials-heading {
        font-size: 2rem;
    }

    .testimonial-card {
        padding: 2rem;
    }

    .review-text {
        font-size: 1.2rem;
    }

    .testimonial-info {
        flex-direction: column;
        align-items: center; /* Center alignment */
    }
}

@media (max-width: 480px) {
    .testimonials {
        padding: 3rem 1rem; /* Adjusted padding */
    }

    .testimonial-card {
        max-width: 100%;
        padding: 1.5rem; /* Slightly reduce padding */
    }

    .nav-btn {
        padding: 0.6rem 1rem;
    }

    .testimonial-img {
        width: 50px;
        height: 50px;
    }

    .review-text {
        font-size: 1rem;
    }
}
