/* body {
    max-width: 100%;
    overflow-x: hidden;
    //overflow-y: hidden;
} */
.home-container{
    //position: absolute;
    position: relative;
    //top: 80px;
    /* display: grid;
    //grid-template-columns: 260px 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 0.1fr 1fr 1fr 1fr;
    grid-template-areas:
      'Navbar' 'Hero' 'MiddleComponent' 'home-courses-text' 'Grid' 'Box' 'Footer'; */
}

/* 🚀 Hero Section */
.home-hero-section {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 85vh;
    background: linear-gradient(135deg, #1E3C72, #2A5298);
    color: #fff;
    padding: 2rem;
    overflow: hidden;
}

/* 🎭 Background Overlay */
.home-hero-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: url('../../../assets/hero-bg.jpg') no-repeat center center/cover;
    opacity: 0.15;
}

/* 📢 Announcement Bar */
.home-hero-announcement-bar {
    background: #FBB03B;
    padding: 12px;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
}

/* 📜 Marquee Animation */
.home-hero-announcement-text {
    white-space: nowrap;
    overflow: hidden;
    animation: home-hero-marquee 10s linear infinite;
}

@keyframes home-hero-marquee {
    from { transform: translateX(100%); }
    to { transform: translateX(-100%); }
}

/* 🎯 Hero Content */
.home-hero-content {
    position: relative;
    z-index: 2;
    max-width: 750px;
    text-align: center;
}

/* 🏆 Title */
.home-hero-title {
    font-size: 3.5rem;
    font-weight: 800;
    line-height: 1.3;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
    animation: fadeInUp 1s ease-in-out;
}

.home-hero-title span {
    color: #FBB03B;
}

/* 📝 Subtext */
.home-hero-subtext {
    font-size: 1.3rem;
    margin: 15px 0;
    opacity: 0.9;
    font-weight: 500;
    color: #fff;
}

/* ✨ CTA Button */
.home-hero-btn {
    margin-top: 20px;
    background: linear-gradient(90deg, #FFD700, #FFA500) !important;
    color: #000 !important;
    font-weight: bold;
    padding: 14px 28px;
    border-radius: 8px;
    font-size: 1.2rem;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 4px 10px rgba(255, 215, 0, 0.5);
}

.home-hero-btn:hover {
    background: linear-gradient(90deg, #FFA500, #FFD700) !important;
    transform: scale(1.05);
}


.home-navbar{
    position: relative;
    //grid-area: 'Navbar';
}
/* .home-hero{
    position: relative;
    //grid-area: 'Hero';
} */

/* .home-middlecomponent{
    //grid-area: 'MiddleComponent';
} */

/* .home-hero{
    //position: fixed;
    top: 100px;
} */

.home-courses-text{
    /* grid-area: 'home-courses-text';
    display: flex;
    flex-direction: column;
    position: relative;
    top: 120px;
    //bottom: 200px;
    font-size: 50px;
    color: black; */
}
/* .home-courses-text input{
    padding: 15px;
    //width: 400px;
    align-items: center;
    align-self: center;
    font-size: 20px;
    border-radius: 50px;
    border-width: 1px;
    border-color: black;
    //margin-bottom: 10px;
} */
/* .home-grid-item{
    display: flex;
    align-items: center;
    justify-content: center;
} */
.home-grid{
    //grid-area: 'Grid';
    position: relative;
    padding: 100px;
    
}

.home-trending-courses{
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 40px;
}
.home-trending-courses-box{
    justify-content: center;
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 40px;
}
.home-free-live-video{
    display: flex;
    flex-direction: row;
    background-color: antiquewhite;
    padding: 30px;
    margin-bottom: 60px;
}
.home-free-live-video-video{
    border-radius: 30px;
}
.home-free-live-video-text1{
    //font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    //padding: 20px;
    //background-color: #fbaf3b;
    //font-weight: bold;
    //border-radius: 30px;
    font-size: 50px;
    font-weight: bold;

}
.home-free-live-video-text2{
    padding: 20px;

}
/* .home-footer{
    //grid-area: 'Footer';
    
} */

@media screen and (max-width: 1260px){
    /* .home-courses-text{
        top: 170px;
    } */
    .home-grid{
        //top: 40px;
        padding: 150px;
    }
    .home-hero-title {
        font-size: 2.8rem;
    }
    .home-hero-subtext {
        font-size: 1.1rem;
    }
    .home-hero-btn {
        font-size: 1.1rem;
        padding: 12px 24px;
    }
}
@media screen and (max-width: 992px){
    .home-free-live-video{
        top: 30px;
        position: relative;
        display: flex;
        flex-direction: column;
    }
    .home-free-live-video-video{
        /* border-radius: 30px; */
        display: flex;
        flex-direction: row;
    }
    .home-free-live-video-text1{
        display: flex;
        flex-direction: row;
    }
    .home-free-live-video-text2{
        display: flex;
        flex-direction: row;
    }
    .home-grid{
        padding: 200px;
    }
    .home-trending-courses{
        margin-top: 60px;
    }
}

@media screen and (max-width: 760px){
    .home-free-live-video{
        display: flex;
        flex-direction: column;
    }
    .home-free-live-video-video{
        /* border-radius: 30px; */
        display: flex;
        flex-direction: row;
    }
    .home-free-live-video-text1{
        display: flex;
        flex-direction: row;
    }
    .home-free-live-video-text2{
        display: flex;
        flex-direction: row;
    }
    .home-grid{
        /* padding: 100px; */
        /* left: 20%; */
        padding: 230px;

    }
    .home-hero-title {
        font-size: 2.4rem;
    }
    .home-hero-subtext {
        font-size: 1rem;
    }
    .home-hero-btn {
        font-size: 1rem;
        padding: 10px 22px;
    }

}
@media screen and (max-width: 650px){
    .home-grid{
        padding: 150px;
    }
}

@media screen and (max-width: 576px){
    .home-container{
        width: 100%; 
    }
    /* .home-courses-text{
        position: relative;
        top: 200px;
        font-size: 30px;
        bottom: 50px;
    } */
    /* .home-courses-text input{
        padding: 3px;
    } */
    .home-grid{
        position: relative;
        padding: 50px;
        top: 100px;

    }

    .home-hero-title {
        font-size: 2rem;
    }
    .home-hero-subtext {
        font-size: 0.95rem;
    }
    .home-hero-btn {
        font-size: 0.9rem;
        padding: 10px 20px;
    }


}

/* 🌟 Smooth Fade-In Animation */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(30px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
