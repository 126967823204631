.enrolled-courses-main{
    /* grid-area: enrolled-courses-main; */
    overflow-y: auto;
    position: relative;
    width: 1000px;
    //margin-top: 100px;
}
.enrolled-courses-course-card{
    justify-content: center;
}
.enrolled-courses-headline{
    font-size: 50px;
    font-weight: bold;
    text-decoration: underline;
    top: 50px;
    left: 40%;
    text-align: center;
    margin-bottom: 10px;
}
@media only screen and (max-width: 1300px){
    .enrolled-courses-main{
        width: 800px;
    }
}
@media only screen and (max-width: 760px){
    .enrolled-courses-main{
        width: 600px;
    }
}
@media only screen and (max-width: 560px){
    .enrolled-courses-headline{
        font-size: 20px;
        left: 25%;
        top: 60px;
    }
    .enrolled-courses-main{
        width: 400px;
    }
}