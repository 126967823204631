.exam-course-module-accordion{
    position: relative;
    width: 100%;
    //align-items: center;
    /* margin-left: 50%;
    margin-top: 30%; */
    border-width: 1px;
    border-color: black;
    left: 90%;
    top: 10%;
}

/* .course-playcirclefield-icon{
    background-color: aqua;
} */

@media only screen and (max-width: 1060px){
    .exam-course-module-accordion{
        width: 80%;
        left: 5%;
        
    }
}
@media only screen and (max-width: 760px){
    .exam-course-module-accordion{
        width: 80%;
        left: 5%;
        
    }
}