/* Main Container */
.lower-testimonial-container {
    text-align: center;
    margin: 50px auto;
    padding: 20px;
    max-width: 1200px;
}

/* Title */
.lower-testimonial-title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
}

/* Testimonial Card */
.lower-testimonial-card {
    display: flex;
    background: #f5f5f5;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    justify-content: space-between;
    align-items: center;
}

/* Left Side - Text */
.lower-testimonial-text {
    width: 55%;
    text-align: left;
    font-size: 16px;
    padding: 20px;
}

/* Student Info */
.lower-testimonial-author {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.lower-testimonial-logo {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.lower-testimonial-author h3 {
    font-size: 18px;
    font-weight: bold;
    color: #252525;
}

.lower-testimonial-author p {
    font-size: 14px;
    color: #555;
}

/* Right Side - Video */
.lower-testimonial-video {
    width: 40%;
    position: relative;
}

.lower-testimonial-video-card {
    background: #a2c5ff;
    border-radius: 12px;
    position: relative;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lower-testimonial-student-img {
    width: 100%;
    height: auto;
    border-radius: 12px;
}

.lower-testimonial-play-button {
    position: absolute;
    background: #fff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: bold;
    color: #ff3d00;
    cursor: pointer;
}

/* Slider Dots */
.lower-testimonial-dots {
    margin-top: 15px;
}

.lower-testimonial-dot {
    height: 10px;
    width: 10px;
    margin: 0 4px;
    background-color: #ddd;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
}

.lower-testimonial-dot.active {
    background-color: #555;
}

/* Live Chat Button */
.lower-testimonial-live-chat-btn {
    margin-top: 20px;
    background: #0066ff;
    color: white;
    border: none;
    padding: 12px 20px;
    font-size: 16px;
    border-radius: 20px;
    cursor: pointer;
}

.lower-testimonial-live-chat-btn:hover {
    background: #0052cc;
}

/* Responsive Design */
@media (max-width: 768px) {
    .lower-testimonial-card {
        flex-direction: column;
    }
    .lower-testimonial-text, .lower-testimonial-video {
        width: 100%;
        text-align: center;
    }
}
