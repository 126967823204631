/* Responsive container */
.blog-hero-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    background-color: #e5efff;
    border-radius: 15px;
    position: relative;
    flex-direction: column; /* Stack items on small screens */
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.8s ease, transform 0.8s ease;
    margin-top: 100px;
  }
  
  .blog-visible {
    opacity: 1;
    transform: translateY(0);
  }
  
  .blog-hero-image {
    width: 100%;
    max-width: 300px;
    margin-bottom: 1.5rem;
  }
  
  .blog-hero-image img {
    width: 100%;
    border-radius: 10px;
  }
  
  .blog-hero-content {
    width: 100%;
    max-width: 600px;
    text-align: center; /* Center text on small screens */
    padding: 1rem;
    background: #ffffff;
    border-radius: 15px;
    box-shadow: -10px 0 20px -10px rgba(0, 0, 0, 0.1);
  }
  
  .blog-hero-content h2 {
    font-size: 2rem; /* Use rem for dynamic sizing */
    color: #e14c8a;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .blog-subject-list,
  .blog-date-range {
    font-size: 1rem;
    color: #333;
  }
  
  .blog-date-range {
    font-size: 0.9rem;
    color: #555;
    margin-bottom: 1rem;
  }
  
  .blog-features-section h5 {
    font-size: 1.2rem;
    background-color: #0033a0;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    display: inline-block;
    margin-top: 1rem;
  }
  
  .blog-course-features {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; /* Make items wrap on small screens */
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .blog-course-features ul {
    list-style-type: none;
    padding: 0;
  }
  
  .blog-course-features li {
    font-size: 0.9rem;
    color: #333;
    margin-bottom: 0.5rem;
    position: relative;
    padding-left: 1.2rem;
  }
  
  .blog-course-features li::before {
    content: '•';
    color: #e14c8a;
    font-size: 1.2rem;
    position: absolute;
    left: 0;
    top: 0;
  }
  
  .blog-enroll-button {
    background-color: #32cd32;
    color: white;
    padding: 0.8rem 2rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 1.5rem;
    transition: background-color 0.3s ease;
  }
  
  .blog-enroll-button:hover {
    background-color: #28a428;
  }
  
  .blog-free-badge {
    position: absolute;
    top: 150px;
    right: 150px;
    background: linear-gradient(145deg, #ff6161, #ff9961);
    color: white;
    border-radius: 50%;
    width: 90px;
    height: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transform: perspective(500px) rotateY(20deg);
  }
  
  .blog-free-badge span:last-child {
    font-size: 0.8rem;
  }
  
  .blog-dotted-arc {
    position: absolute;
    top: -5px;
    right: -10px;
    width: 120px;
    height: 120px;
    border: 2px dotted #ff6161;
    border-radius: 50%;
    transform: rotate(-35deg);
  }
  
  /* Media Queries for Responsive Design */
  @media (min-width: 768px) {
    .blog-hero-container {
      flex-direction: row; /* Row layout on medium+ screens */
    }
  
    .blog-hero-image {
      width: 40%;
    }
  
    .blog-hero-content {
      width: 60%;
      text-align: left;
    }
  
    .blog-hero-content h2 {
      font-size: 2.5rem;
    }
  
    .blog-subject-list,
    .blog-date-range {
      font-size: 1.2rem;
    }
  
    .blog-course-features li {
      font-size: 1rem;
    }
  }
  