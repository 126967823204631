.hero-popup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
}

.hero-popup-inner{
    position: relative;
    padding: 32px;
    width: 60%;
    height: 60%;
    //max-width: 640px;
    background-color: #FBB03B;
}

.hero-popup-inner .hero-close-btn{
    position: absolute;
    top: 16px;
    right: 16px;
}