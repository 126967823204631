.centered{
    padding-top: 5px;
    padding-left: 30px;
    padding-right: 30px;
    //display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}
/* .course-fullpage{
    display: flex;
    flex-direction: column;
} */
.course-course-container{
    display: flex;
    flex-direction: row;
    /* width: 100%; */
    height: 10vh;
    display: grid;
    grid-template-columns: repeat(auto-fit, 0.15fr);
    grid-template-areas: 'firstRowElement1 input firstRowElement3 firstRowElement4';
    grid-gap: 10px;
    //padding-top: 50px;
    //padding-bottom: 100px;
    //border-radius: 50px;


}


.course-filter-menu-icon{
    display: none;
}
.course-filter-leftbar{
    //display: none;
    position: absolute;
    margin-top: 150px;
}

.course-cover-image{
    position: relative;
    width: 100%;
    margin-bottom: 20px;
}
.course-dropdown1{
    display: none;
}
.course-option-selection{
    background-color: #ca701d;
}
.course-filter-middle-part{
    display: none;
    /* display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 300px;
    position: relative;
    margin-top: 30px;
    border-radius: 4px;
    background-color: antiquewhite;
    width: 60%;
    height: 60px;
    align-items: center;
    margin-left: 25%; */
}

.course-grid{
    /* top: 100px; */
    //padding: 50px;
}
/* .course-grid Grid{
    display: flex;
    flex-direction: row;
} */
.course-left-box1{
    display: grid;
    background-color: #FDE0B2;
    border-radius: 10px;
}
.course-left-box1-header{
    color: blue;
    font-size: large;
    font-weight: bold;
    margin-top: 10%;
}

.course-left-box2{
    display: grid;
    border-radius: 10px;
    border-width: 2px;
    border-color: black;
}



.course-left-bar-form-control1{
    border-radius: 17px;
    background-color: #FDE0B2;
    width: 277px;
    height: 230px;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.course-left-bar-form-control2{
    border-radius: 17px;
    background-color: #ECE1E1;
    width: 277px;
    /* height: 1085px; */
    /* border-width: 2px;
    border-color: black; */
    align-content: center;
    align-items: center;
    justify-content: center;
}
.course-left-bar-formgroup{
}

.course-middle-container{
    display: flex;
    flex-direction: row;
}

.course-course-leftbar{
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 960px){
    
    
    .course-cover-image{
        display: none;
    }
    .course-grid{
        justify-content: center;
        vertical-align: middle;
    }
}

@media only screen and (max-width: 760px){
    .course-filter-menu-icon{
        position: absolute;
        margin-top: 80px;
    }
    .course-left-sidebar-filter{
        display: block;
    }
    .course-course-leftbar{
        display: none;
    }
    .course-leftbar{
        display: none;
    }
}



@media only screen and (max-width: 560px){
    .course-filter-middle-part{
        //display: none;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 50px;
        position: fixed;
        margin-top: 90px;
        border-radius: 4px;
        background-color: antiquewhite;
        width: 70%;
        height: 60px;
        align-items: center;
        margin-left: 15%;
        z-index: 9;
    }
    .course-filter-middle-part input{
        padding: 5px;
        //width: 400px;
        align-items: center;
        align-self: center;
        font-size: 20px;
        border-radius: 50px;
        border-width: 1px;
        border-color: black;
        width: 200px;
        //margin-bottom: 10px;
    }
    .course-course-container{
        padding: 50px;
        position: relative;
        margin-bottom: 100px;
        /* left: 20px; */
        
    
    }
    .course-filter-middle-part{
        width: 80%;
    }
    .course-middle-container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}