.course-centered{
    padding-top: 5px;
    padding-left: 30px;
    padding-right: 30px;
    //display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    margin-top: 30px;
}
/* .course-fullpage{
    display: flex;
    flex-direction: column;
} */
.course-container{
    position: relative;
    display: flex;
    flex-direction: row;
    /* width: 100%; */
    //height: 15vh;
    padding-top: 80px;
    display: grid;
    grid-template-columns: repeat(auto-fit, 0.15fr);
    grid-template-areas: 'firstRowElement1 input firstRowElement3 firstRowElement4';
    grid-gap: 10px;
    /* padding-top: 20px; */
    //padding-bottom: 100px;
    //border-radius: 50px;

}
.first-row-element-1{
    grid-area: firstRowElement1;
    //position: relative;
    //flex-direction: column;
    background-color: #ca701d;
    //width: 35%;
    height: 40px;
    color: white;
    /* text-align: center;
    vertical-align: middle; */
    //left: 10px;
    //top: 10px;
    border-radius: 10px;

}
.first-row-element-2{
    grid-area: input;
    //position: relative;
    //width: 35%;
    height: 40px;
    //left: 250px;
    //bottom: 30px;
    background-color:#f2f2f2;
    border-width: 1px;
    border-color: black;
    border-radius: 50px;
    text-align: center;
}
.first-row-element-3{
    grid-area: firstRowElement3;
    //position: relative;
    //width: 25%;
    //width: 25%;
    height: 40px;
    //left: 480px;
    //bottom: 70px;
    background-color: #c5425d;
    color: white;
    text-align: center;
    border-radius: 10px;
}
.first-row-element-4{
    grid-area: firstRowElement4;
    //position: relative;
    //width: 25%;
    //width: 25%;
    height: 40px;
    //left: 650px;
    background-color: #e6dcdc;
    border-radius: 10px;
    color: black;
    text-align: center;
}

.course-syllabus-part{
    font-size: 60px;
    justify-items: center;
    position: relative;
}

.course-middle-text{
    position: relative;
    font-size: 40px;
    font-weight: bold;
    margin-top: 80px;
}

.course-details-container{
    display: flex;
    flex-direction: row;
    /* width: 100%; */
    height: 20vh;
    display: grid;
    grid-template-columns: repeat(auto-fit, 0.15fr);
    grid-template-areas: 'secondRowElement1 secondRowElement2 secondRowElement3 secondRowElement4';
    //grid-gap: 10px;
    padding-top: 50px;

}

.second-row-element-1{
    grid-area: secondRowElement1;
    //position: relative;
    //flex-direction: column;
    background-color: #FB6A77;
    //width: 35%;
    height: 40px;
    color: white;
    /* text-align: center;
    vertical-align: middle; */
    //left: 10px;
    //top: 10px;
    //border-radius: 10px;
    //font-size: bold;
    font-weight: bold;
    cursor: pointer;

}
.second-row-element-1-selected{
    grid-area: secondRowElement1;
    height: 40px;
    color: white;
    font-weight: bold;
    cursor: pointer;
    background-color: #FBB03B;
}
.second-row-element-2{
    grid-area: secondRowElement2;
    //position: relative;
    //width: 35%;
    height: 40px;
    //left: 250px;
    //bottom: 30px;
    background-color:#C1D5F6;
    //border-width: 1px;
    //border-color: black;
    //border-radius: 50px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
}
.second-row-element-2-selected{
    grid-area: secondRowElement2;
    height: 40px;
    background-color: #FBB03B;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
}
.second-row-element-3{
    grid-area: secondRowElement3;
    //position: relative;
    //width: 25%;
    //width: 25%;
    height: 40px;
    //left: 480px;
    //bottom: 70px;
    background-color: #ECE1E1;
    color: black;
    text-align: center;
    //border-radius: 10px;
    font-weight: bold;
    cursor: pointer;
}
.second-row-element-3-selected{
    grid-area: secondRowElement3;
    height: 40px;
    background-color: #FBB03B;
    color: black;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
}
.second-row-element-3:hover{
    background-color: #FBB03B;
}
.second-row-element-4{
    grid-area: secondRowElement4;
    //position: relative;
    //width: 25%;
    //width: 25%;
    height: 40px;
    //left: 650px;
    background-color: #C1D5F6;
    //border-radius: 10px;
    color: black;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
}
.second-row-element-4-selected{
    grid-area: secondRowElement4;
    height: 40px;
    background-color: #FBB03B;
    color: black;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
}
.second-row-element-4:hover{
    background-color: #FBB03B;
}

.course-details-text-1{
    position: relative;
    display: flex;
    flex-direction: row;
    font-size: 25px;
    font-weight: bold;
    /* align-items: flex-start;
    align-self: flex-start;
    align-content: flex-start; */
    margin-left: 10%;
}
.course-details-box1{
    display: flex;
    flex-direction: row;
    background-color: #FBF7F7;
    width: 80%;
    height: 50vh;
    margin-left: 10%;
    align-items: center;
    margin-top: 10px;
    border-radius: 30px;
}
.course-details-box1-1{
    display: flex;
    flex-direction: column;
    margin-left: 10%;
    font-size: 20px;
}
.course-details-text-2{
    display: flex;
    flex-direction: row;
    font-size: 25px;
    font-weight: bold;
    /* align-items: flex-start;
    align-self: flex-start;
    align-content: flex-start; */
    margin-left: 10%;
    margin-top: 10px;
}
.course-details-box2{
    display: flex;
    flex-direction: row;
    background-color: #FDE0B2;
    width: 80%;
    align-content: center;
    justify-content: center;
    margin-top: 10px;
    border-radius: 30px;
}
.syllabus-list-container{
    display: flex;
    flex-direction: row;
    gap: 10px;
    height: auto;
}
.course-details-box2-1{
    display: flex;
    flex-direction: column;
    margin-left: 10%;
    font-size: 20px;
}

.course-details-text-3{
    display: flex;
    flex-direction: row;
    font-size: 25px;
    font-weight: bold;
    /* align-items: flex-start;
    align-self: flex-start;
    align-content: flex-start; */
    margin-left: 10%;
    margin-top: 10px;
}
/* .course-module-accordion{
    display: flex;
    flex-direction: row;
    background-color: #FDE0B2;
    width: 80%;
    height: 30vh;
    margin-left: 10%;
    //align-items: center;
    margin-top: 10px;
    border-radius: 30px;
} */
.section-title {
    color: #34495e;
    font-size: 1.5rem;
    margin-bottom: 10px;
    border-bottom: 2px solid #3498db;
    display: inline-block;
}
.syllabus-list,
.exam-list,
.project-list {
    display: flex;
    flex-direction: column;
  list-style-type: none;
  padding: 0;
  align-items: flex-start;
}

.syllabus-list li,
.exam-list li,
.project-list li {
  padding: 8px 0;
  font-size: 1.1rem;
  color: #2c3e50;
  border-bottom: 1px solid #ddd;
}

.syllabus-list li:last-child,
.exam-list li:last-child,
.project-list li:last-child {
  border-bottom: none;
}

.syllabus-list li::before,
.exam-list li::before,
.project-list li::before {
  content: "•";
  color: #3498db;
  margin-right: 10px;
  font-size: 1.5rem;
}

@media only screen and (max-width: 960px){
    .course-container{
        //padding: 100px;
        //position: relative;
        /* left: 20px;
        right: 20px;
        width: 100%; */
        //height: 30vh;
        top: 80px;
        display: grid;
        grid-template-rows: repeat(auto-fit, 1fr);
        grid-template-areas: 'firstRowElement1'
                            'input' 'firstRowElement3' 'firstRowElement4';
        grid-gap: 20px;
    
    }
    .course-details-container{
        /* display: flex;
        flex-direction: row; */
        /* width: 100%; */
        //padding: 100px;
        position: relative;
        /* left: 20px;
        right: 20px;
        width: 100%; */
        //height: 30vh;
        display: grid;
        grid-template-columns: repeat(auto-fit, 0.10fr);
        grid-template-areas: 'secondRowElement1 secondRowElement2 secondRowElement3 secondRowElement4';
        margin-top: 140px;
        //grid-gap: 20px;
        //padding-top: 50px;
    
    }
    /* .course-middle-text{
        position: relative;
        //font-size: 100%;
        font-weight: bold;
        //top: 150px;
        //padding: 100px;
    } */

    .course-middle-text{
        margin-top: -70px;
        //margin-bottom: 40px;
        padding: 30px;

    }
    .course-details-box1-1 li{
        /* display: flex;
        flex-direction: column;
        margin-left: 20%; */
        font-size: 30px;
    }
    .course-details-box2-1 li{
        /* display: flex;
        flex-direction: column;
        margin-left: 20%; */
        font-size: 30px;
    }
    .course-details-text-1{
        margin-top: 20px;
    }
    
}

@media only screen and (max-width: 760px){
    .course-details-container{
        display: grid;
        grid-template-rows: repeat(auto-fit, 1fr);
        grid-template-areas: 'secondRowElement1'
                        'secondRowElement2' 'secondRowElement3' 'secondRowElement4';
    }
    .course-details-container{
        margin-bottom: 180px;
    }

    .course-details-box1{
        width: 80%;
        height: 50vh;
        /* margin-left: 5%; */
        //margin-top: 120px;
    }
    .course-details-box1 li{
        font-size: 20px;
    }
    .course-details-box2{
        width: 90%;
        /* margin-left: 10%; */
    }
    .course-details-box2 li{
        font-size: 20px;
    }

    .course-details-text-1{
        margin-top: 200px;
    }
    .course-middle-text{
        margin-bottom: 50px;
    }
    .course-details-text-3{
        position: relative;
        margin-top: 100px;
    }
    .syllabus-list-container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

}