/* General Styles */
.scholarship-container {
    width: 800px;
    padding: 30px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    align-self: center;
    justify-self: center;
    position: relative;
    margin-bottom: 150px;
    top: 100px;
}

/* Hero Header Section */
.scholarship-header {
    text-align: center;
    background: linear-gradient( rgb(100, 174, 144), #FBB03B);
    color: white;
    padding: 40px 20px;
    border-radius: 10px;
    margin-bottom: 30px;
}

.scholarship-header h1 {
    font-size: 28px;
    margin-bottom: 10px;
}

.scholarship-header p {
    font-size: 16px;
}

/* Countdown Timer */
.countdown {
    background: #ff6f61;
    color: white;
    padding: 10px;
    text-align: center;
    border-radius: 8px;
    font-weight: bold;
    margin-bottom: 20px;
}

/* Form Grid Layout */
.scholarship-form-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.scholarship-form-group {
    display: flex;
    flex-direction: column;
}

.scholarship-form-group label {
    font-size: 14px;
    font-weight: 600;
    color: #444;
    margin-bottom: 8px;
}

.scholarship-form-group input,
.scholarship-form-group select,
.scholarship-form-group textarea {
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 14px;
    transition: border-color 0.3s ease;
}

.scholarship-form-group input:focus,
.scholarship-form-group select:focus,
.scholarship-form-group textarea:focus {
    border-color: #007bff;
    outline: none;
}

.scholarship-form-group textarea {
    resize: vertical;
    min-height: 120px;
}

.scholarship-full-width {
    grid-column: span 2;
}

/* File Upload Styling */
.scholarship-form-group ul {
    list-style: none;
    padding: 0;
    margin-top: 5px;
}

.scholarship-form-group ul li {
    font-size: 14px;
    color: #555;
    background: #f4f4f4;
    padding: 5px;
    border-radius: 5px;
    margin-top: 3px;
}

/* Submit Button */
.scholarship-submit-button {
    width: 100%;
    padding: 14px;
    background-color: #FBB03B;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.scholarship-submit-button:hover {
    background-color: green;
    transform: scale(1.05);
}

/* Confirmation Message */
.confirmation-message {
    text-align: center;
    background: #e8f5e9;
    color: #2E8B57;
    padding: 20px;
    border-radius: 10px;
    font-size: 18px;
    font-weight: bold;
    margin-top: 20px;
}

/* Testimonials Section */
.testimonials {
    background: #f8f8f8;
    padding: 30px;
    border-radius: 10px;
    margin-top: 40px;
    text-align: center;
}

.testimonial-card {
    padding: 20px;
    border-left: 5px solid #4CAF50;
    font-style: italic;
}

/* Responsive Design */
@media (max-width: 768px) {
    .scholarship-form-grid {
        grid-template-columns: 1fr;
    }

    .scholarship-full-width {
        grid-column: span 1;
    }

    .scholarship-container {
        width: 90%;
        padding: 20px;
    }

    .scholarship-header h1 {
        font-size: 24px;
    }

    .scholarship-header p {
        font-size: 14px;
    }
}
