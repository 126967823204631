/* Global Styles */
/* body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    background: linear-gradient(135deg, #2196f3, #3f51b5);
    color: #ffffff;
  } */
  
  .exam-page {
    max-width: 900px;
    /* margin-: 50px auto; */
    padding: 20px;
    background: #ffffff;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    color: #333;
  }  
  
  /* Header Styles */
  .exam-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    border-bottom: 2px solid #f1f1f1;
    padding-bottom: 10px;
  }
  
  .exam-header h1 {
    font-size: 24px;
    font-weight: bold;
    color: #3f51b5;
  }
  
  .timer {
    font-size: 18px;
    font-weight: bold;
    color: #f44336;
    background: rgba(255, 0, 0, 0.1);
    padding: 5px 10px;
    border-radius: 5px;
  }
  
  /* Question Section */
  .question-section {
    margin-bottom: 30px;
  }
  
  .question-section h2 {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .question-section p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .options {
    display: flex;
    flex-direction: column;
  }
  
  .option {
    padding: 15px;
    margin-bottom: 10px;
    border: 2px solid #ddd;
    border-radius: 8px;
    font-size: 20px;
    cursor: pointer;
    background: #f9f9f9;
    color: #333;
    transition: all 0.3s ease;
    text-align: center;
  }
  
  .option:hover {
    background: #e3f2fd;
    border-color: #2196f3;
  }
  
  .option.selected {
    background: #d1e7dd;
    border-color: #28a745;
    color: #155724;
    font-weight: bold;
  }
  
  /* Navigation */
  .navigation {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 30px;
  }
  
  .nav-button {
    width: 50px;
    height: 50px;
    margin: 5px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    transition: all 0.3s ease;
  }
  
  .nav-button.answered {
    background: #28a745;
    color: white;
  }
  
  .nav-button.unanswered {
    background: #f44336;
    color: white;
  }
  
  .nav-button:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  }
  
  /* Submit Section */
  .submit-section {
    text-align: center;
  }
  
  .submit-button {
    padding: 12px 25px;
    font-size: 18px;
    font-weight: bold;
    background: #4caf50;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s ease;
  }
  
  .submit-button:hover {
    background: #45a049;
    transform: translateY(-3px);
  }
  
  .submit-button:active {
    background: #388e3c;
    transform: translateY(0);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .exam-page {
      margin: 20px;
      padding: 15px;
    }
  
    .exam-header {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .exam-header h1 {
      margin-bottom: 10px;
    }
  
    .timer {
      align-self: flex-end;
    }
  
    .nav-button {
      width: 40px;
      height: 40px;
      font-size: 12px;
    }
  
    .option {
      font-size: 14px;
    }
  }
  
  @media (max-width: 480px) {
    .nav-button {
      width: 35px;
      height: 35px;
      font-size: 12px;
    }
  
    .submit-button {
      font-size: 16px;
      padding: 10px 20px;
    }
  }
  