/* Global Styles */

  
  .cart-page {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 100px;
  }
  
  /* Cart Items */
  .cart-items {
    flex: 2;
    background: white;
    border-radius: 10px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  .cart-items h2 {
    color: #fbb03b;
    margin-bottom: 15px;
    font-size: 1.8rem;
  }
  
  .cart-item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding: 15px 0;
    justify-content: space-between;
  }
  
  .cart-item:last-child {
    border-bottom: none;
  }
  .cart-item-actions{
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .item-image {
    width: 80px;
    height: 80px;
    border-radius: 8px;
    margin-right: 20px;
  }
  .item-info{
    align-items: center;
    justify-items: center;
  }
  
  .item-info h3 {
    font-size: 1.2rem;
    color: #333;
  }
  
  .item-actions {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-top: 10px;
  }
  
  .quantity-control {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .quantity-control button {
    padding: 8px 12px;
    background-color: #fbb03b;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
  }
  
  .quantity-control button:hover {
    background-color: #e09c33;
  }
  
  .save-later {
    padding: 6px 10px;
    background-color: #6c757d;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
  }
  
  .remove-button {
    padding: 6px 12px;
    background-color: #ff5252;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  /* Order Summary */
  .order-summary {
    flex: 1;
    background: white;
    border-radius: 10px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-height: 300px;
    position: sticky;
    top: 20px;
  }
  
  .order-summary h2 {
    color: #fbb03b;
    margin-bottom: 15px;
    font-size: 1.8rem;
  }
  
  .summary-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 1rem;
  }
  
  .summary-item.total {
    font-weight: bold;
    font-size: 1.3rem;
    color: #fbb03b;
  }
  
  .checkout-button {
    width: 100%;
    padding: 12px;
    background-color: #fbb03b;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .checkout-button:hover {
    background-color: #e09c33;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .cart-page {
      flex-direction: column;
    }
  
    .order-summary {
      position: static;
      margin-top: 20px;
    }
  }
  