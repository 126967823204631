.course-module-accordion{
    width: 80%;
    align-items: center;
    left: 10%;
    //margin-top: 5px;
    border-width: 1px;
    border-color: black;
}
/* .course-playcirclefield-icon{
    background-color: aqua;
} */

.course-accordion-icon{
    margin-right: 5px;
}
.course-free-video{
    background-color: #24C78C;
    color: white;
    border-radius: 10px;
    width: 134px;
    height: 28px;
    text-align: center;
}
.course-free-video-lock{
    background-color: #D9D9D9;
    border-radius: 10px;
    width: 134px;
    height: 28px;
    text-align: center;
}
.course-details-lock-pop-up-message-container{
    display: flex;
    flex-direction: row;
}
.course-details-lock-pop-up-message{
    margin-top: 15%;
    font-size: 25px;
    font-weight: 500;
}
.course-lock-pop-up-enroll-button{
    margin-top: 40px;
    background-color: green;
    width: 60px;
    padding: 10px;
    color: white;
    font-weight: bold;
    border-radius: 20px;
    margin-left: 50%;
    cursor: pointer;
}

@media only screen and (max-width: 760px){
    .course-module-accordion-main{
        margin-bottom: 50px;
    }
    .course-module-accordion{
        width: 80%;
        top: 30px;
    }
    .course-free-video-lock{
        width: 60px;
    }
}

@media only screen and (max-width: 560px){
    .course-free-video-lock{
        width: 40px;
    }
    .course-free-video{
        width: 40px;
    }
}