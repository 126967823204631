/* Loading Spinner */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #ddd;
  border-top: 5px solid #4caf50;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Exam Card */
.exam-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: box-shadow 0.3s ease-in-out;
  width: 400px; /* Add max-width to prevent cards from being too narrow */
  margin: 0 auto; /* Center the card within its grid cell */
}

.exam-card:hover {
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2);
}

.card-header {
  background: linear-gradient(to right, #4caf50, #2196f3);
  color: #fff;
  padding: 15px;
  text-align: center;
}

.card-header h2 {
  margin-bottom: 5px;
}

.card-body {
  padding: 15px;
  font-size: 0.9rem;
  color: #555;
}

.card-body p {
  margin: 5px 0;
}

.status {
  margin-top: 10px;
  font-weight: bold;
  text-align: center;
}

.status.passed {
  color: green;
}

.status.failed {
  color: red;
}

.status.pending {
  color: orange;
}

.chart-container {
  margin-top: 15px;
  height: 200px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Page Layout */
.exams-container {
  padding: 20px;
}

.page-title {
  text-align: center;
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

/* Grid Layout */
.exam-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
}

/* Responsive Grid */
@media (min-width: 768px) {
  .exam-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
}

@media (min-width: 1200px) {
  .exam-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 30px; /* Increase gap for larger screens */
  }
}

@media (max-width: 560px) {
  .card-header {
    padding: 10px;
  }

  .card-body {
    padding: 10px;
    font-size: 0.8rem;
  }

  .page-title {
    font-size: 1.5rem;
  }
  .exam-card{
    width: 300px;
  }
}