.NavbarItems{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    box-shadow:0 5px 150px 0 rgba(0, 0, 0, 0.25);
    height: 80px;
    position: fixed;
    left: 50%;
    width: 100%;
    transform: translate(-50%);
    z-index: 999;
    background-color: #f4f1f1;
}
.nav-menu{
    display: grid;
    grid-template-columns: repeat(10,auto);
    list-style: none;
    align-items: center;
    justify-content: center;
}

.nav-links{
    text-decoration: none;
    color: #222;
    font-size: 1 2rem;
    font-weight: 600;
    padding: 0.7rem 1rem;
    white-space: nowrap;
}

.nav-links:hover{
    background-color: #FBB03B;
    color: #fff;
    border-radius: 4px;
    transition: all 0.5s ease-in-out;
}

.nav-links-mobile{
    display: none;
}

.navbar-login-button{
  background-color: #FBB03B;
  padding: 0.5rem 1rem;
  white-space: nowrap;
  border-radius: 0.3rem;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.navbar-login-button:hover{
    background-color: #151516;
    color: #fff;
}


.dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }

  .dropdown {
    position: relative;
    display: inline-block;
  }

  .menu-icons{
    display: none;
  }
  .navbar-logo{
    cursor: pointer;
    position: relative;
    //left: 10%;
  }
  #basic-button{
    //width: 20%;
    height: 80%;
  }
    /* This targets the paper element of the Menu */
  .MuiMenu-paper {
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3); /* Add shadow for better visibility */
    min-width: 160px; /* Adjust width */
    z-index: 2; /* Ensure it appears above other elements */
    position: absolute; /* Make sure it doesn't push other elements */
  }
  .profile-menu{
    position: absolute;
    margin-right: 100px;
    width: 200px;
  }

  .navbar-right-side {
    display: flex;
    align-items: center; /* Keep items centered vertically */
    gap: 10px;
  }
  
  .navbar-profile-icon-mobile{
      display: flex;
      align-self: center;
  }
  .navbar-profile-icons {
    cursor: pointer;
    font-size: 2rem;
    position: relative;
  }
  .navbar-profile-icons-image{
    width: 30px; /* Set a fixed width */
    height: 30px; /* Set a fixed height */
    border-radius: 50%; /* Makes the image circular */
    object-fit: cover; /* Ensures the image covers the circular area without stretching */
    border: 2px solid #fff; /* Optional: Add a border to the image */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for styling */
    cursor: pointer;
  }
  .navbar-profile-menu-list {
    position: absolute;
    background-color: #f4f1f1;
    width: 200px;
    display: flex;
    flex-direction: column;
    top: 80px;
    right: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  .navbar-profile-menu-list li {
    cursor: pointer;
    padding: 10px;
    text-align: center;
    border-bottom: 1px solid #ddd;
  }
  
  .navbar-profile-menu-list li:last-child {
    border-bottom: none;
  }
  
  .navbar-profile-menu-list li:hover {
    background-color: #FBB03B;
  }
  .navbar-profile-menu-logout{
    background-color: red;
  }
  .navbar-cart{
    cursor: pointer;
    color: #FBB03B;
    font-size: 30px;
  }
  .navbar-cart-wrapper {
    position: relative;
    display: inline-block;
}

.cart-badge {
    position: absolute;
    top: -5px;
    right: -10px;
    background-color: red;
    color: white;
    font-size: 12px;
    border-radius: 50%;
    padding: 4px 6px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    min-width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}

  

  @media screen and (max-width: 760px){
    .NavbarItems{
        z-index: 99;
    }
    .nav-menu{
        display: flex;
        flex-direction: column;
        list-style: none;
        justify-content: flex-start;
        background-color: #fff;
        width: 100%;
        height: auto;
        position: absolute;
        top: 0;
        left: -100%;
        opacity: 0;
        align-items: stretch;
        padding: 80px 0 30px 0;
        margin: 0;
        transition: 0.3s ease-in-out;
        border-radius: 13px;
    }
    .nav-menu.active{
        left: 0;
        opacity: 1;
        z-index: -1;
        transition: 0.3s ease-in-out;
    }

    .nav-links{
        display: block;
        width: 100%;
        font-size: 1.2rem;
        padding: 1rem 0;
    }

    .nav-links:hover{
        background: #FBB03B;
        transition: 0.5s ease-in-out;
    }

    .navbar-login-button{
        display: none;

    }

    .nav-links-mobile{
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: auto;
        border-radius: 4px;
        width: 60%;
        background: #FBB03B;
        text-decoration: none;
        color: #fff;
        font-size: 1.2 rem;
        font-weight: 700;
        letter-spacing: 2px;

    }
    .navbar-logo{
        position: absolute;
        left: 30%;
    }
    .menu-icons{
        //display: block;
        cursor: pointer;
        font-size: 2rem;
        display: flex;
        position: flex-start;
        //color: #050000;
        //background-color: #050000;
      } 
    .navbar-profile-icon-desktop{
        display: none;
    }
    .navbar-right-side{
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
    .navbar-profile-icon-mobile{
        display: flex;
        align-self: center;
        width: 20px;
    }

    
  }

  /* @media screen and (max-width: 960px){
    .navbar-logo{
      //position: absolute;
      left: 30%;
    }
    
    
  } */
  @media screen and (max-width: 576px){
    .navbar-logo{
        width: 30%;
    }
  }