.enroll-container{
    position: relative;
    top: 100px;
    display: flex;
    flex-direction: column;
    margin: auto;
    height: 800px;
    width: 800px;
    /*margin-top: 200px;*/
    background: #1147b2;
    padding-bottom: 30px;
}

.enroll-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    width: 100%;
    margin-top: 30px;
}

.enroll-text{
    color: white;
    font-size: 48px;
    font-weight: 700;
}

.enroll-underline{
    width: 61px;
    height: 6px;
    background: white;
    border-radius: 9px;
}

.enroll-inputs{
    margin-top: 55px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.enroll-input{
    display: flex;
    align-items: center;
    margin: auto;
    width: 480px;
    height: 80px;
    background: #eaeaea;
    border-radius: 6px;
}



.enroll-input input{
    height: 50px;
    width: 400px;
    background: transparent;
    border: none;
    outline: none;
    color: #797979;
    font-size: 19px;
}

.enroll-submit-container{
    display: flex;
    gap: 30px;
    margin: 60px auto;
}

.enroll-submit{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 59px;
    color: #fff;
    background: #58c233;
    border-radius: 50px;
    font-size: 19px;
    font-weight: 700;
    cursor: pointer;
}

.enroll-footer{
    position: absolute;
    top: 50px;
}

.enroll-page-top-text{
    position: relative;
    top: 100px;
    font-size: 30px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}