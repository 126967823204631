/* saved-for-later-page.css */

/* General Styling */

  
  /* Container for Cart and Saved Items */
  .saved-for-later-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    padding-top: 80px;
  }
  
  h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }
  
  /* Saved for Later Section */
  .saved-for-later-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 40px;
  }
  
  .saved-for-later-item {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 48%;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease;
  }
  
  .saved-for-later-item:hover {
    transform: scale(1.05);
  }
  
  .saved-for-later-item-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-bottom: 2px solid #f1f1f1;
  }
  
  .saved-for-later-item-info {
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  h3 {
    font-size: 1.25rem;
    color: #333;
    margin: 0;
  }
  
  p {
    font-size: 1rem;
    color: #555;
    margin: 0;
  }
  
  /* Action Buttons */
  .saved-for-later-item-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  .saved-for-later-item-actions button,
  .saved-for-later-item-actions svg {
    background-color: #ff8c00;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .saved-for-later-item-actions button:hover,
  .saved-for-later-item-actions svg:hover {
    background-color: #ff6600;
  }
  
  .saved-for-later-item-actions svg {
    cursor: pointer;
  }
  
  .saved-for-later-item-actions button {
    font-size: 0.875rem;
    margin-left: 10px;
  }
  
  /* Cart and Saved for Later Headers */
  .saved-for-later-header,
  .saved-for-later-cart-header {
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: #333;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .saved-for-later-header .saved-for-later-header-title,
  .saved-for-later-cart-header .saved-for-later-header-title {
    font-weight: bold;
  }
  
  /* Footer Styling */
  footer {
    background-color: #333;
    color: white;
    text-align: center;
    padding: 20px;
    margin-top: 40px;
  }
  
  /* Order Summary Section */
  .saved-for-later-order-summary {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 40px;
    width: 100%;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .saved-for-later-order-summary .saved-for-later-summary-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  
  .saved-for-later-summary-item span {
    font-size: 1rem;
    color: #555;
  }
  
  .saved-for-later-total {
    font-size: 1.25rem;
    font-weight: bold;
  }
  
  .saved-for-later-checkout-button {
    background-color: #ff8c00;
    color: white;
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .saved-for-later-checkout-button:hover {
    background-color: #ff6600;
  }
  
  .saved-for-later-items .move-to-cart-button {
    background-color: #28a745;
    border: none;
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .move-to-cart-button:hover {
    background-color: #218838;
  }
  