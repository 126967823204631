/* Contact Page Container */
.contact-container {
    max-width: 1000px;
    padding: 20px;
    background: #fff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    position: relative;
    top: 100px;
    margin-bottom: 150px;

    /* Flexbox centering */
    margin-left: auto;
    margin-right: auto;
}

/* Header Section */
.contact-header {
    text-align: center;
    background: linear-gradient(to right, #4CAF50, #2E8B57);
    color: white;
    padding: 30px;
    border-radius: 10px 10px 0 0;
}

.contact-header h1 {
    margin: 0;
    font-size: 28px;
}

/* Contact Form & Info Layout */
.contact-content {
    display: flex;
    justify-content: space-between;
    padding: 30px;
    gap: 20px;
}

/* Contact Form */
.contact-form {
    flex: 1;
    background: #f8f8f8;
    padding: 20px;
    border-radius: 8px;
}

.contact-form label {
    font-weight: bold;
    display: block;
    margin-top: 10px;
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
}

.contact-form textarea {
    resize: vertical;
}

.contact-submit-button {
    margin-top: 15px;
    width: 100%;
    padding: 12px;
    background-color: #FBB03B;
    color: white;
    border: none;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.contact-submit-button:hover {
    background-color: green;
}

/* Contact Info */
.contact-info {
    flex: 1;
    padding: 20px;
    background: #fff7e6;
    border-radius: 8px;
}

.contact-info h2 {
    font-size: 18px;
    margin-top: 15px;
}

.contact-info p {
    font-size: 14px;
}

/* Social Links */
.contact-socials {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.contact-socials .social-icon {
    text-decoration: none;
    font-weight: bold;
    color: #007bff;
}

/* Google Map */
.contact-map {
    margin-top: 20px;
}

/* Responsive */
@media (max-width: 768px) {
    .contact-content {
        flex-direction: column;
    }
}
