.container_middle_comp{
    display: flex;
    flex-direction: row;
    position: relative;
    top: 100px;
    //left: 50px;
    //bottom: 100px;
    //width: auto;
    height: 500px;
    //bottom: 80px;
}

.box-left{
    display: flex;
    flex-direction: column;
    position: relative;
    //top: 10px;
    //bottom: 0;
    left: 100px;
    background-color:#c1d5f6;
    //height: 100px;
    width: 40%;
    padding: 200px;
    //border-radius: 150px;
    //align-self: flex-start;
    border-top-left-radius: 30px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 30px;
    align-items: center;
    justify-content: center;
}

.box-right{
    display: flex;
    flex-direction: column;
    position: relative;
    //top: 10px;
    //bottom: 10;
    right: 120px;
    //left: 500px;
    background-color:#fcdfb2;
    //height: '100px';
    width: 95%;
    padding: 70px;
    //border-radius: 150px;
    border-top-left-radius: 230px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 0px;
    //align-self: flex-end;
}

.middle-left-image{
    //position: relative;
    //align-items: center;
    position: absolute;
    width: 50%;
    right: 50%;

}
.box-right-content{
    width: 500px;
    height: 100px;
    align-self: center;
    align-items: center;
    align-self: center;
}

.box-right-header{
    color: #b0325f;
    font-size: 50px;
    font-weight: bold;
}
.box-right-sub{
    font-size: 20px;
    font-weight: bold;
}
.box-right-sub-duration{
    font-size: 25px;
    font-weight: bolder;
}
.box-right-sub-duration1{
    font-size: 20px;
    //font-weight: bolder;
}
.box-right-course-feature{
    background-color: blue;
    color: white;
    text-align: start;
    padding: 5px;
    font-size: 20px;
    width: 90%;
}
.box-right-course-feature-list{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.box-right-course-feature-left-list{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: large;
    font-weight: bold;
    color: #b0325f;
}
.box-right-course-feature-right-list{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: large;
    font-weight: bold;
    color: #b0325f;
}
.box-right-course-admit{
    background-color: #58C233;
    color: white;
    width: fit-content;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    font-weight: bold;
    font-size: 15px;
    border-radius: 10px;
    cursor: pointer;
    /* align-self: center; */
}

.course-fee-capsule {
    position: absolute;
    right: 10px;
    margin-top: 40px;
    width: 150px; /* Adjust size */
    height: 150px; /* Adjust size */
    background: linear-gradient(180deg, #921616, #ff9999); /* 3D effect */
    border-radius: 100px; /* Rounded corners for the tablet */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.course-fee-capsule .course-fee-text {
    font-size: 20px; /* Adjust font size */
    font-weight: bold;
}

.dotted-line {
    position: absolute;
    top: 50%; /* Center vertically */
    left: -40px; /* Position it based on your design */
    width: 60px; /* Width of the dotted line */
    height: 60px; /* Height of the dotted line */
    border: 2px dotted #000; /* Dotted line style */
    border-radius: 50%; /* Circular shape */
    transform: rotate(-45deg); /* Adjust rotation to make it 'S' shaped */
}


/* @media screen and (max-width: 1460px){
    .box-left{
        display: none;
    }
    .box-right{
        display: flex;
        flex-direction: column;
        position: relative;
        //top: 10px;
        //bottom: 10;
        //right: 200px;
        left: 180px;
        background-color:#fcdfb2;
        height: 550px;
        width: 500px;
        //padding: 200px;
        //border-radius: 150px;
        border-radius: 30px;
        //align-self: flex-end;

    }
    .box-right-content{
        width: 300px;
        //padding: 100px;
        height: 70px;
        align-self: center;
        align-items: center;
    }
    
    .box-right-header{
        color: #b0325f;
        font-size: 30px;
        font-weight: bold;
    }
    .box-right-sub{
        font-size: 15px;
        font-weight: bold;
    }
    .box-right-sub-duration{
        font-size: 20px;
        font-weight: bolder;
    }
    .box-right-sub-duration1{
        font-size: 15px;
        //font-weight: bolder;
    }
    .box-right-course-feature{
        background-color: blue;
        color: white;
        text-align: start;
        //padding: 5px;
        font-size: 15px;
        width: 90%;
    }
    .box-right-course-feature-list{
        display: flex;
        flex-direction: column;
        //justify-content: space-between;
    }
    .box-right-course-feature-left-list{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: large;
        font-weight: bold;
        color: #b0325f;
    }
    .box-right-course-feature-right-list{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: large;
        font-weight: bold;
        color: #b0325f;
    }
    .box-right-course-admit{
        background-color: #58C233;
        color: white;
        width: fit-content;
        position: relative;
        //top: 50%;
        //left: 50%;
        transform: translate(-50%, -50%);
        //padding: 5px;
        font-weight: bold;
        //align-self: center;
    }
    
} */

@media screen and (max-width: 1260px){
    .box-left{
        display: none;
    }
    /* .box-right{
        display: flex;
        flex-direction: column;
        position: relative;
        //top: 10px;
        //bottom: 10;
        //right: 200px;
        left: 50px;
        background-color:#fcdfb2;
        height: 550px;
        width: 90%;
        //padding: 200px;
        //border-radius: 150px;
        border-radius: 30px;
        //align-self: flex-end;

    } */
    .box-right{
        display: flex;
        flex-direction: column;
        position: relative;
        left: 180px;
        background-color:#fcdfb2;
        height: 550px;
        width: 500px;
        border-radius: 30px;

    }
    .box-right-content{
        width: 300px;
        //padding: 100px;
        height: 70px;
        align-self: flex-start;
        align-items: center;
    }
    .box-right-header{
        font-size: 30px;
    }
    /* .box-right{
        position: absolute;
        transform: translate(-50%, -50%);
        top: 60%;
        left: 50%;
        width: 90%;
    } */
}
@media screen and (max-width: 770px){
    .box-right-sub{
        font-size: 20px;
    }
    .box-right-sub-duration1{
        font-size: 20px;
        font-weight: bold;
    }
    .box-right-course-feature{
        font-size: 20px;
    }
    .box-right-course-admit{
        padding: 15px;
        font-weight: bold;
        font-size: 20px;
    }
}

@media screen and (max-width: 560px){
    .box-right{
        left: 10px;
        width: 95%;
        justify-self: flex-start;
    }
    .box-right-content{
        width: 200px;
    }
    
    .box-right-header{
        font-size: 20px;
    }
    .box-right-sub{
        font-size: 15px;
    }
    .box-right-sub-duration{
        font-size: 15px;
    }
    .box-right-sub-duration1{
        font-size: 10px;
    }
    .box-right-course-feature{
        font-size: 15px;
    }
    .box-right-course-feature-left-list{
        font-size: 15px;
    }
    .box-right-course-feature-right-list{
        font-size: 15px;
    }
    .course-fee-capsule {
        width: 100px; /* Adjust size */
        height: 100px; /* Adjust size */
        
    }
    .course-fee-capsule .course-fee-text {
        font-size: 15px; /* Adjust font size */
    }
    
}