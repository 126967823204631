.slider-container {
  width: 100%;
  height: 90vh;
  overflow: hidden;
  position: relative;
  margin-top: 0; /* Avoid margin that causes overlap */
  z-index: 1; /* Ensure it's below the navbar */
}
  
  .swiper {
    width: 100%;
    height: 100%;
  }
  
  .slide {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .slider-content {
    /* background: rgba(0, 0, 0, 0.5); */
    background: #FBB03B;
    color: #fff;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    animation: fadeInUp 1s ease-in-out;
    opacity: 0.8;
    position: absolute;
    bottom: 25px;
  }
  
  .slider-content h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  .slider-content p {
    font-size: 1.2rem;
  }

  .home-slider-component-enroll-button {
    display: inline-block;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 20px;
    cursor: pointer;
    text-align: center;
    z-index: 10;
    position: relative;
    padding: 5px;
    font-weight: 500;
  }
  
  .home-slider-component-enroll-button:hover {
    background-color: #45a049; /* Darker green */
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

@media only screen and (max-width: 1060px){
    .slider-container{
        height: 50vh;
    }
    .slider-content h1 {
        font-size: 1.5rem;
        margin-bottom: 10px;
    }
    .slider-content p {
        font-size: 1rem;
    }
    .home-slider-component-enroll-button {
        margin-top: 10px;
        font-size: 14px;
    }

}
@media only screen and (max-width: 560px){
    .slider-container{
        height: 50vh;
    }
    .slider-content h1 {
        font-size: 1.2rem;
        margin-bottom: 10px;
    }
    .slider-content p {
        font-size: 0.8rem;
    }
    .home-slider-component-enroll-button {
        margin-top: 10px;
        font-size: 12px;
    }
}
