/* 📊 LMS Stats Section */
.lms-stats {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4rem 2rem;
    background: linear-gradient(135deg, #FBB03B 0%, #ff9a3b 100%);
    color: white;
    text-align: center;
    position: relative;
    overflow: hidden;
}

/* 🌀 Glassmorphism Background Effect */
.lms-stats::before {
    content: "";
    position: absolute;
    width: 500px;
    height: 500px;
    background: rgba(255, 255, 255, 0.2);
    filter: blur(100px);
    top: -100px;
    left: -100px;
    border-radius: 50%;
}

/* 🏆 Stats Container */
.lms-stats-container {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    justify-content: center;
}

/* 🎖️ Individual Stat Card */
.lms-stat-card {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 2rem;
    border-radius: 16px;
    min-width: 250px;
    text-align: center;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s;
    position: relative;
}

/* ✨ Hover Effects */
.lms-stat-card:hover {
    transform: translateY(-5px);
    box-shadow: 0px 10px 30px rgba(255, 255, 255, 0.3);
}

/* 📌 Icon Styling */
.lms-icon {
    font-size: 3rem;
    margin-bottom: 0.8rem;
    color: white;
}

/* 🔢 Number Styling */
.lms-stat-card h3 {
    font-size: 3rem;
    font-weight: bold;
    color: white;
}

/* 📝 Description */
.lms-stat-card p {
    font-size: 1.2rem;
    font-weight: 500;
}

/* 📱 Responsive Design */
@media (max-width: 1024px) {
    .lms-stats-container {
        flex-direction: column;
        align-items: center;
    }

    .lms-stat-card {
        width: 80%;
        margin-bottom: 1.5rem;
    }
}

@media (max-width: 768px) {
    .lms-stat-card {
        width: 90%;
    }
}

@media (max-width: 480px) {
    .lms-stat-card {
        width: 100%;
    }

    .lms-stat-card h3 {
        font-size: 2.5rem;
    }

    .lms-stat-card p {
        font-size: 1rem;
    }
}
