.profile-holder{
    display: flex;
    flex-direction: row;
    overflow: visible;
    
}
.profile-main{
    position: relative;
    left: 20%;
    border-width: 2px;
    border-color: black;
    height: 600px;
    top: 5%;
    width: 700px;

}
.profile-info{
    margin-top: 5%;
    margin-left: 10%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 80%;
}
.profile-info update{
    background-color: green;
    width: 80px;
    border-radius: 20px;
    color: white;
    padding: 5px;
    align-self: center;
    cursor: pointer;
}
.profile-info-1{
    display: flex;
    flex-direction: row;
    gap: 5px;
}
.profile-info-1 input{
    border-width: 1px;
    padding: 10px;
    width: 225px;
}
.profile-info-1 label{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.profile-info-2{
    display: flex;
    flex-direction: row;
    gap: 5px;
}
.profile-info-2 input{
    border-width: 1px;
    padding: 10px;
    width: 450px;
}
.profile-info-2 label{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.profile-user-photo{
    margin-left: 50px;
    justify-content: center;
    justify-items: center;
}
.profile-name{
    font-size: 20px;
    font-weight: 500;
    margin-top: 30px;
    //margin-left: 35px;
}
.profile-image-display-before {
    height: 150px;
    width: 150px;
    margin-top: 10px;
    cursor: pointer;
}

.profile-image-display-after {
    height: 200px;
    width: 200px;
    border-radius: 50%;
    margin-top: 10px;
    cursor: pointer;
}
.update-button{
    background-color: #FBB03B;
    width: 200px;
    align-self: center;
}

@media only screen and (max-width: 760px){
    .profile-holder{
        display: flex;
        flex-direction: column;
    }
    /* .profile-main{
        left: 1%;
        height: 600px;
        width: 400px;
    
    } */
    .profile-user-photo{
        margin-top: 50px;
    }
    .profile-name{
        margin-left: 10px;
    }
    .profile-info{
        margin-left: 10%;
        width: 50%;
    }
    .profile-info-1 input{
        width: 150px;
    }
    .profile-info-2 input{
        width: 300px;
    }
}

@media only screen and (max-width: 560px){
    .profile-main{
        width: 100px;
    }
    .profile-info{
        width: 80%;
    }
}