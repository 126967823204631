.user-profile-settings-holder{
    display: flex;
    flex-direction: column;
    margin-top: 20%;
    margin-left: 40%;
    width: 600px;
    height: 70%;
    /* border-width: 1px; */
    align-items: center;
    
}

.user-profile-settings-holder-input input{
    border-width: 1px;
    padding: 10px;
    width: 350px;
}
.user-profile-settings-holder-input label{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.user-profile-settings-holder-form button{
    margin-top: 20px;
}
@media only screen and (max-width: 960px){
    .user-profile-settings-holder{
        width: 500px;
        margin-left: 10%;
        height: 50%;
        
    }
    .user-profile-settings-holder-input input{
        width: 300px;
    }
    
}
@media only screen and (max-width: 560px){
    .user-profile-settings-holder{
        width: 300px;
        margin-left: 10%;
        height: 50%;
        
    }
    .user-profile-settings-holder-input input{
        width: 200px;
    }
    .user-profile-settings-holder-form button{
        /* margin-left: 20px; */
        margin-top: 10px;
    }
}
.change-password-container { 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    color: #fff;
    /* text-align: center; */
    }
    
    h2 {
      margin-bottom: 20px;
    }
    
    .change-password-form {
      display: flex;
      flex-direction: column;
    }
    
    .change-password-input {
      margin-bottom: 15px;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      width: 100%;
      color:darkslategray
    }
    
    .change-password-button {
      padding: 10px;
      background-color: #007bff;
      border: none;
      color: white;
      border-radius: 5px;
      cursor: pointer;
    }
    
    .change-password-button:hover {
      background-color: #0056b3;
    }
    
    .loading {
      text-align: center;
    }
    
    .change-password-message {
      color: green;
      margin-top: 10px;
    }
    
    .change-password-error {
      color: red;
      margin-top: 10px;
    }
    /* ChangePassword.css */
  .input-container {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .change-password-input {
    width: 100%;
    padding-right: 2.5rem; /* Adjust padding to make room for the icon */
  }
  
  .show-password-button {
    position: absolute;
    right: 10px;
    color: black;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
  }
  
  .validation-error {
    color: red;
    font-size: 0.9rem;
  }
  
  .change-password-message {
    color: green;
    font-size: 0.9rem;
  }
  
  .change-password-error {
    color: red;
    font-size: 0.9rem;
  }
  