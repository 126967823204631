/* 📩 Newsletter Section */
.newsletter {
    text-align: center;
    padding: 5rem 2rem;
    background: linear-gradient(135deg, #FBB03B 0%, #4893ef 100%);
    color: white;
    position: relative;
    overflow-x: hidden;
    max-width: 100vw;
    box-sizing: border-box;
}

/* 🏆 Heading */
.newsletter-heading {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    position: relative;
    z-index: 2;
}

/* ✨ Subheading */
.newsletter-subheading {
    font-size: 1.2rem;
    opacity: 0.9;
    margin-bottom: 2rem;
}

/* 📩 Subscription Box */
.subscription-box {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(15px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 2rem;
    border-radius: 16px;
    max-width: 500px;
    margin: auto;
    text-align: center;
    position: relative;
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;
}

/* 🌀 Hover Effects */
.subscription-box:hover {
    transform: translateY(-5px);
    box-shadow: 0px 10px 30px rgba(255, 255, 255, 0.2);
}

/* 📧 Input Field */
.subscription-input {
    width: 100%;
    padding: 1rem;
    font-size: 1.2rem;
    border: none;
    border-radius: 8px;
    outline: none;
    text-align: center;
    color: #333;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.1);
}

/* 🚀 Subscribe Button */
.subscribe-btn {
    margin-top: 1rem;
    background: white;
    color: #0072FF;
    border: none;
    padding: 1rem 2rem;
    border-radius: 8px;
    font-size: 1.2rem;
    font-weight: bold;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    width: 100%;
}

.subscribe-btn:hover {
    background: gold;
    color: white;
    transform: scale(1.05);
}

/* 📱 Responsive Design */
@media (max-width: 768px) {
    .newsletter-heading {
        font-size: 2rem;
    }

    .subscription-box {
        padding: 1.5rem;
    }

    .subscription-input {
        font-size: 1rem;
        padding: 0.8rem;
    }

    .subscribe-btn {
        font-size: 1rem;
        padding: 0.8rem 1.5rem;
    }
}

@media (max-width: 480px) {
    .newsletter {
        padding: 3rem 1rem;
    }

    .subscription-box {
        max-width: 100%;
    }
}
