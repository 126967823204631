:root {
    --sidebar-width: 250px;
    --sidebar-collapsed-width: 70px;
    --primary-color: #2563eb;
    --sidebar-bg: #1f2937;
    --header-height: 60px;
}

.teacher-dashboard {
    display: flex;
    min-height: 100vh;
    background-color: #f3f4f6;
}

/* Sidebar Styles */
.teacher-sidebar {
    width: var(--sidebar-width);
    background-color: var(--sidebar-bg);
    color: white;
    transition: width 0.3s ease;
    position: fixed;
    height: 100vh;
    z-index: 1000;
}

.teacher-sidebar.teacher-collapsed {
    width: var(--sidebar-collapsed-width);
}

.teacher-logo-section {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #374151;
}

.teacher-logo {
    font-size: 1.5rem;
    font-weight: bold;
}

.teacher-toggle-btn {
    position: absolute;
    right: -12px;
    top: 30px;
    background: var(--sidebar-bg);
    border: none;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.teacher-nav-links {
    text-decoration: none;
    color: #222;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0.7rem 1rem;
    white-space: nowrap;
}

.teacher-nav-item {
    padding: 12px 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.2s;
    text-decoration: none;
    color: white;
}

.teacher-nav-item:hover {
    background-color: #374151;
}

.teacher-nav-item.teacher-active {
    background-color: #374151;
    border-left: 4px solid var(--primary-color);
}

.teacher-nav-item span {
    margin-left: 12px;
}

.teacher-sidebar.teacher-collapsed .teacher-nav-item span {
    display: none;
}

.teacher-sidebar.teacher-collapsed .teacher-logo span {
    display: none;
}

/* Main Content Styles */
.teacher-main-content {
    flex: 1;
    margin-left: var(--sidebar-width);
    transition: margin-left 0.3s ease;
}

.teacher-sidebar.teacher-collapsed + .teacher-main-content {
    margin-left: var(--sidebar-collapsed-width);
}

.teacher-header {
    background: white;
    height: var(--header-height);
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.teacher-header-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: #1f2937;
}

.teacher-header-actions {
    display: flex;
    gap: 16px;
}

.teacher-header-btn {
    background: none;
    border: none;
    padding: 8px;
    cursor: pointer;
    border-radius: 50%;
    transition: background-color 0.2s;
}

.teacher-header-btn:hover {
    background-color: #f3f4f6;
}

.teacher-content {
    padding: 24px;
}

.teacher-card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 24px;
}

.teacher-card {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
}

.teacher-card:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.teacher-card-title {
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 12px;
    color: #1f2937;
}

.teacher-card-content {
    color: #6b7280;
    line-height: 1.5;
}

/* Responsive Design */
@media (max-width: 768px) {
    .teacher-sidebar {
        transform: translateX(-100%);
        position: fixed;
    }

    .teacher-sidebar.teacher-collapsed {
        transform: translateX(0);
        width: var(--sidebar-width);
    }

    .teacher-main-content {
        margin-left: 0;
    }

    .teacher-sidebar.teacher-collapsed + .teacher-main-content {
        margin-left: 0;
    }

    .teacher-sidebar.teacher-collapsed .teacher-nav-item span {
        display: inline;
    }

    .teacher-sidebar.teacher-collapsed .teacher-logo span {
        display: inline;
    }

    .teacher-card-grid {
        grid-template-columns: 1fr;
    }
}
