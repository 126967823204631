
/*
body {
  background-image: url("./assets/Banner.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  backface-visibility: hidden;
}
*/


.App {
  text-align: center;
}


