.signup-container{
    position: relative;
    top: 80px;
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 100%;
    /*margin-top: 200px;*/
    background: #fff;
    padding-bottom: 30px;
}

.signup-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 9px; */
    width: 100%;
    margin-top: 10px;
}

.signup-text{
    color: #EA8208;
    font-size: 48px;
    font-weight: 700;
}

.signup-underline{
    width: 61px;
    height: 6px;
    background: #EA8208;
    border-radius: 9px;
}

.signup-inputs{
    margin-top: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.signup-input{
    display: flex;
    align-items: center;
    margin: auto;
    width: 420px;
    height: 80px;
    background: #eaeaea;
    border-radius: 20px;
    position: relative;
}



.signup-input input{
    height: 50px;
    width: 400px;
    background: transparent;
    border-radius: 10px;
    color: #797979;
    font-size: 19px;
    padding: 10px;
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
}

.signup-img{
    margin-left: 10%;
}

.signup-forgot-password{
    padding-left: 30px;
    margin-top: 20px;
    color: #797979;
    font-size: 18px;
}

.signup-forgot-password span{
    color: #EA8208;
    cursor: pointer;
}

.signup-submit-container{
    display: flex;
    gap: 20px;
    margin-bottom: 60px;
}

.signup-submit{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 59px;
    color: #fff;
    background: #EA8208;
    border-radius: 50px;
    font-size: 19px;
    font-weight: 700;
    cursor: pointer;
}
.error-message {
    color: red;
    font-size: 0.9rem;
    margin-top: 0.3rem;
  }

  .message {
    color: green; /* or red for error messages */
    margin: 10px 0;
    font-size: 0.9rem;
  }
  
  .hint {
    color: gray;
    font-size: 0.8rem;
    margin-top: 5px;
  }

  .toggle-password {
    position: absolute;
    right: 10px;
    cursor: pointer;
    color: #888;
  }
  
  

@media screen and (max-width: 576px){
    .signup-input{
        
        width: 70%;
        
    }
    .signup-input input{
        
        width: 60%;
        
    }
    .signup-submit{
        width: 120px;
    }
    .signup-forgot-password{
        padding-left: 15px;
    }
}