/* .course-card-box{
    border-radius: 20px;

} */
.home-course-card-box Card{
    background-color: #ECE1E1;
}
.home-enroll-button{
    background-color: #58C233;
    color: white;
    width: 89px;
    height: 30px;
    font-size: 15px;
    font-weight: bold;
    border-radius: 4px;
    padding: 3px;
}
.home-course-card-footer-content{
    display: flex;
    flex-direction: column;
    width: 130px;
    height: 33px;
    background-color: #D9D9D9;
    align-content: center;
    vertical-align: middle;
}
.home-course-card-footer-content div1{
    display: flex;
    flex-direction: row;
    font-size: 10px;
    font-weight: bold;
    align-self: center;
    color: #58C233;
}
.home-course-card-footer-content div4{
    display: flex;
    flex-direction: row;
    align-self: center;
}
.home-course-card-footer-content div2{
    display: flex;
    flex-direction: row;
    font-size: 10px;
    font-weight: bold;
    align-self: center;
    text-decoration: line-through;
    text-decoration-color: #EA8208;
    gap: 2px;
}
.home-course-card-footer-content div3{
    display: flex;
    flex-direction: row;
    font-size: 10px;
    font-weight: bold;
    align-self: center;
    margin-left: 5px;
    gap: 2px;
}

.home-course-card-content{
    cursor: pointer;
    background-color: #ECE1E1;
}
.home-course-card-content Typography{
    color: #1556FC;
    width: 104px;
    height: 60px;
}

.home-course-card-review{
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    align-content: center;
}

.home-course-card-review div1{
    display: flex;
    flex-direction: row;
    font-weight: 500;
}
.home-course-card-review div11{
    display: flex;
    flex-direction: column;
    width: 60px;
    height: 16px;
}
.home-course-card-review div12{
    display: flex;
    flex-direction: column;
    width: 4px;
    height: 15px;
    color: #EA8208;
    font-weight: bold;
    margin-left: 5px;
}
.home-course-card-review div13{
    display: flex;
    flex-direction: column;
    width: 80px;
    height: 16px;
}
.home-course-card-review div2{
    display: flex;
    flex-direction: row;
    gap: 2px;
    font-weight: 500;
}
.home-course-card-review div21{
    color: #EA8208;
}