
  .exam-page {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .exam-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .exam-header h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
    color: #333;
  }
  
  .exam-header p {
    font-size: 1rem;
    color: #666;
  }
  
  /* Exam List Styling */
  .exam-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .exam-card {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .exam-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  .exam-card-content {
    padding: 20px;
  }
  
  .exam-title {
    font-size: 1.5rem;
    color: #2c3e50;
    margin-bottom: 10px;
  }
  
  .exam-description {
    font-size: 1rem;
    color: #7f8c8d;
    margin-bottom: 15px;
  }
  
  .exam-info {
    font-size: 0.9rem;
    color: #555;
    margin-bottom: 20px;
  }
  
  /* Start Exam Button */
  .start-exam-button {
    display: block;
    width: 100%;
    padding: 10px 0;
    font-size: 1rem;
    background-color: #3498db;
    color: #fff;
    text-transform: uppercase;
    border: none;
    border-radius: 0 0 10px 10px;
    transition: background-color 0.3s ease;
  }
  
  .start-exam-button:hover {
    background-color: #2980b9;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .exam-header h1 {
      font-size: 2rem;
    }
  
    .exam-header p {
      font-size: 0.9rem;
    }
  
    .exam-title {
      font-size: 1.3rem;
    }
  
    .exam-description {
      font-size: 0.9rem;
    }
  
    .start-exam-button {
      font-size: 0.9rem;
    }
  }
  