/* General styling */
.checkout-page {
  font-family: Arial, sans-serif;
  color: #333;
  max-width: 800px;
  margin: auto;
  top: 150px;
  padding: 20px;
}

.header h1 {
  text-align: center;
  color: #444;
  font-size: 28px;
  margin-bottom: 30px;
}

.checkout-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Order Summary Section */
.checkout-order-summary {
  border: none;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background: linear-gradient(135deg, #f0f8ff, #e0f7fa);
  padding: 30px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
}
.order-summary-footer{
  display: flex;
  flex-direction: row;
}

.order-summary:hover {
  transform: scale(1.02);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.order-summary h2 {
  font-size: 24px;
  color: #00796b;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.course-item {
  display: grid;
  grid-template-columns: 1fr auto;
  row-gap: 10px;
  font-size: 16px;
  color: #555;
  justify-content: space-between;
}

.course-item p {
  margin: 0;
  line-height: 1.5;
}

.course-item strong {
  color: #004d40;
}

.course-item .amount {
  font-size: 16px;
  color: #555;
}

.course-item .amount span {
  font-size: 20px;
  font-weight: bold;
  color: #004d40;
}

.course-item .total span {
  color: #d32f2f;
}

.course-item .total {
  grid-column: span 2;
  font-size: 20px;
  font-weight: bold;
  color: #d32f2f;
  margin-top: 10px;
  text-align: center;
}

.coupon-input {
  width: 70%;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #ddd;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  transition: border-color 0.3s ease;
}

.coupon-input:focus {
  border-color: #00796b;
  outline: none;
}

.apply-coupon {
  width: calc(30% - 10px);
  margin-left: 10px;
  background-color: #009688;
  color: #fff;
  font-weight: bold;
  padding: 10px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.apply-coupon:hover {
  background-color: #00796b;
}

/* User Information Section */
.input-group {
  display: flex;
  flex-direction: column;
}

.input-group label {
  font-size: 14px;
  color: #666;
  margin-bottom: 5px;
}

.input-group input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 15px;
}

/* Payment Method Section */
.payment-method h2 {
  font-size: 20px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

/* Payment Option Cards */
.payment-options {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.payment-option-card {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px;
  border: 2px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
  flex: 1 1 calc(33.333% - 20px); /* Three columns */
  text-align: center;
  justify-content: center;
  transition: all 0.3s ease;
  font-size: 16px;
  color: #555;
  min-width: 120px;
}

.payment-option-card i {
  font-size: 24px;
  color: #777;
}

.payment-option-card:hover {
  border-color: #2196f3;
  background-color: #f0f8ff;
}

.payment-option-card.selected {
  border-color: #2196f3;
  background-color: #e3f2fd;
  color: #2196f3;
}

/* Card Info Input Styles */
.card-info, .mobile-banking-options {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  margin-top: 10px;
}

.card-info label, .mobile-banking-options label {
  font-size: 14px;
  color: #666;
}

.card-info input, .mobile-banking-options input, .mobile-banking-options select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

/* Select Dropdown */
.mobile-banking-options select {
  background-color: #fff;
  cursor: pointer;
}

/* Review & Confirm Section */
.review-confirm {
  text-align: center;
}

.review-confirm p {
  font-size: 18px;
  font-weight: bold;
}

.terms {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
}

.terms label {
  margin-left: 5px;
}

.place-order {
  width: 100%;
  padding: 15px;
  background-color: #2196f3;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s;
}

.place-order:hover {
  background-color: #1976d2;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .payment-option-card {
    flex: 1 1 calc(50% - 20px); /* Two columns on tablets */
  }
}

@media (max-width: 768px) {
  .course-item {
    grid-template-columns: 1fr;
  }

  .payment-option-card {
    flex: 1 1 100%; /* One column on small devices */
  }

  .coupon-input {
    width: 100%;
    margin-bottom: 10px;
  }

  .apply-coupon {
    width: 100%;
    margin-left: 0;
  }

  .place-order {
    font-size: 16px;
    padding: 12px;
  }
}
