.course-details-tabs-course-tabs {
    width: 100%;
    max-width: 1200px; /* Restrict width on larger screens */
    margin: 20px auto;
    font-family: Arial, sans-serif;
    padding: 0 10px; /* Add padding for smaller screens */
}
  
.course-details-tabs-tabs {
    display: flex;
    flex-wrap: wrap; /* Allow tabs to wrap on smaller screens */
    justify-content: flex-start;
    border-bottom: 2px solid #ddd;
}
  
.course-details-tabs-tabs button {
    background: none;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 16px;
    color: #333;
    transition: 0.3s;
    flex-grow: 1; /* Make all tabs equal width on small screens */
    text-align: center; /* Center-align the text for mobile */
    z-index: 10;
    position: relative;
    display: block;
}
  
.course-details-tabs-tabs button.active {
    border-bottom: 3px solid black;
    font-weight: bold;
    color: #f2ae41;
}
  
.course-details-tabs-tabs button:hover {
    background-color: #f2ae41;
    color: white;
}
  
.course-details-tabs-tab-content {
    padding: 20px;
    font-size: 14px;
    line-height: 1.6;
    color: #555;
}

/* Responsive Design */
@media (max-width: 768px) {
    .course-details-tabs-tabs {
        flex-direction: column; /* Stack tabs vertically on small screens */
    }

    .course-details-tabs-tabs button {
        flex-grow: 0; /* Remove equal width */
        font-size: 14px; /* Reduce font size for smaller screens */
        padding: 10px;
    }

    .course-details-tabs-tab-content {
        padding: 15px;
        font-size: 13px; /* Adjust content font size */
    }
}

@media (max-width: 560px) {
    .course-details-tabs-course-tabs {
        margin-top: 70%;
    }
    .course-details-tabs-tabs button {
        font-size: 12px; /* Further reduce font size for mobile */
        padding: 8px;
    }

    .course-details-tabs-tab-content {
        font-size: 12px; /* Adjust content font size for mobile */
        padding: 10px;
    }
}
