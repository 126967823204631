.course-container_middle_comp{
    display: flex;
    flex-direction: row;
    position: relative;
    top: 20px;
    //left: 50px;
    //bottom: 100px;
    //width: auto;
    height: 500px;
    //bottom: 80px;
}

.course-box-left{
    display: flex;
    flex-direction: column;
    position: relative;
    //top: 10px;
    //bottom: 0;
    left: 100px;
    background-color:#c1d5f6;
    //height: 100px;
    width: 800px;
    padding: 200px;
    //border-radius: 150px;
    //align-self: flex-start;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
    align-items: center;
    justify-content: center;
}

.course-box-right{
    display: flex;
    flex-direction: column;
    position: relative;
    //top: 10px;
    //bottom: 10;
    right: 150px;
    //left: 500px;
    background-color:#fcdfb2;
    //height: '100px';
    width: 800px;
    padding: 70px;
    //border-radius: 150px;
    border-top-left-radius: 0px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 0px;
    //align-self: flex-end;
}

.course-middle-left-image{
    //position: relative;
    //align-items: center;
    position: absolute;
    width: 50%;
    height: 80%;
    right: 40%;

}
.course-box-right-content{
    width: 500px;
    height: 100px;
    align-self: center;
    align-items: center;
    align-self: center;
}

.course-box-right-header{
    color: #b0325f;
    font-size: 50px;
    font-weight: bold;
}
.course-box-right-sub{
    font-size: 20px;
    font-weight: bold;
}
.course-box-right-sub-duration{
    margin-top: 40px;
    font-size: 25px;
    font-weight: bolder;
}
.course-box-right-sub-duration1{
    font-size: 20px;
    //font-weight: bolder;
}
.course-box-right-course-feature{
    display: flex;
    flex-direction: row;
    
}
/* .course-box-right-course-feature-text{
    display: flex;
    flex-direction: row;
    background-color: blue;
    color: white;
    text-align: start;
    padding: 5px;
    font-size: 20px;
    width: 60%;
} */
/* .course-box-right-course-feature-list{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
} */

.course-box-right-course-feature-left-list-title{
    color: black;
    text-align: start;
    padding: 5px;
    /* //font-size: 20px; */
    font-weight: bold;
    width: 90%;
}
.course-box-right-course-feature-left-list{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: large;
    font-weight: bold;
    color: #b0325f;
    margin-right: 100px;
}
/* .course-box-right-course-feature-right-list{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: large;
    font-weight: bold;
    color: #b0325f;
} */
.course-box-right-course-admit{
    background-color: #58C233;
    color: white;
    width: fit-content;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    font-weight: bold;
    font-size: 15px;
    border-radius: 10px;
    /* align-self: center; */
}

.course-middle-circle{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    align-self: flex-end;
    align-content: flex-end;
}

.course-middle-circle1{
    /* display: flex;
    flex-direction: column; */
    /* position: relative;
    background: lightblue;
    border-radius: 50%;
    width: 100px;
    height: 100px; */
    background: #EA8208;
    border-radius: 50%;
    height: 186px;
    width: 186px;
    position: relative;
   /* 
    Child elements with absolute positioning will be 
    positioned relative to this div 
   */
}
.course-middle-circle2{
    /* display: flex;
    flex-direction: column; */
    /* position: absolute;
    background: green;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    top: 50%;
    left: 50%;
    margin: -150px 0px 0px -150px; */
    /* align-items: center;
    align-self: center;
    align-content: center;
    vertical-align: middle; */
    position: absolute;
   background: #FFFFFF;
   border-radius: 50%;
   height: 140px;
   width: 140px;
   /*
    Put top edge and left edge in the center
   */
   top: 50%;
   left: 50%;
   margin: -70px 0px 0px -70px;
   /* 
    Offset the position correctly with
    minus half of the width and minus half of the height  
   */
}
.course-circle-text{
    position: relative;
    top: 40%;
    text-align: center;
    line-height: 0px;
    font-size: 20px;
    font-weight: bold;
    color: #1556FC;
}
.course-circle-text1{
    position: relative;
    top: 50%;
    text-align: center;
    line-height: 0px;
    font-size: 20px;
    font-weight: bold;
    text-decoration: line-through;
    text-decoration-color: #EA8208;
}
.course-circle-text2{
    position: relative;
    top: 55%;
    text-align: center;
    line-height: 0px;
    font-size: 20px;
    font-weight: bold;
}
.course-middle-circle-admit-now-p{
    background-color: #40e72a;
    color: white;
    width: fit-content;
    position: relative;
    top: 30px;
    left: 5%;

    transform: translate(-50%, -50%);
    padding: 10px;
    font-weight: bold;
    font-size: 15px;
    border-radius: 10px;
    cursor: pointer;
}

.course-middle-circle-admit-now{
    background-color: #6d46da;
    color: white;
    width: fit-content;
    position: relative;
    top: 30px;
    left: 5%;

    transform: translate(-50%, -50%);
    padding: 10px;
    font-weight: bold;
    font-size: 15px;
    border-radius: 10px;
    cursor: pointer;
}

.course-middle-component-pop-up{
    gap: 10px;
    display: flex;
    flex-direction: row;
}
.course-middle-component-submit-button{
    background-color: #58C233;
    width: 30%;
    border-radius: 30px;
    color: white;
    padding: 10px;
    font-size: 25px;
    font-weight: bold;
    margin-left: 35%;
    margin-top: 20px;
    cursor: pointer;
}
.course-enroll-popup-input{
    display: flex;
    flex-direction: row;
    border-width: 1px;
    border-color: black;
    margin-bottom: 10px;
    margin-left: 35%;
    border-radius: 30px;
    width: 30%;
    height: 40px;
    padding: 20px;
}
.course-enroll-popup-input span{
    background-color: grey;
}
.course-middle-component-pop-up div1{
    display: flex;
    flex-direction: column;
}

/* @media screen and (max-width: 1460px){
    .course-box-left{
        display: none;
    }
    .course-box-right{
        //display: flex;
        //flex-direction: column;
        //position: relative;
        //top: 10px;
        //bottom: 10;
        //right: 200px;
        //left: 180px;
        //background-color:#fcdfb2;
        height: 700px;
        width: 700px;
        //padding: 200px;
        //border-radius: 150px;
        border-radius: 30px;
        //align-self: flex-end;

    }
    .course-box-right-content{
        width: 300px;
        //padding: 100px;
        height: 70px;
        align-self: center;
        align-items: center;
    }
    
    /* .course-box-right-header{
        color: #b0325f;
        font-size: 30px;
        font-weight: bold;
    }
    .course-box-right-sub{
        font-size: 15px;
        font-weight: bold;
    } */
    /* .course-box-right-sub-duration{
        font-size: 20px;
        font-weight: bolder;
    }
    .course-box-right-sub-duration1{
        font-size: 15px;
        //font-weight: bolder;
    } */
    /* .course-box-right-course-feature{
        background-color: blue;
        color: white;
        text-align: start;
        //padding: 5px;
        font-size: 15px;
        width: 90%;
    } */
    /* .course-box-right-course-feature-list{
        display: flex;
        flex-direction: column;
        //justify-content: space-between;
    }
    .course-box-right-course-feature-left-list{
        display: flex;
        flex-direction: column;
        //align-items: flex-start;
        font-size: large;
        font-weight: bold;
        color: #b0325f;
        margin-right: 80px;
        //width: 70%;
    }
    .course-middle-circle{
        display: flex;
        flex-direction: column;
        margin-bottom: 150px;
    } */
    /* .course-box-right-course-feature-left-list{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: large;
        font-weight: bold;
        color: #b0325f;
    }
    .course-box-right-course-feature-right-list{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: large;
        font-weight: bold;
        color: #b0325f;
    } */
    /* .course-box-right-course-admit{
        background-color: #58C233;
        color: white;
        width: fit-content;
        position: relative;
        //top: 50%;
        //left: 50%;
        transform: translate(-50%, -50%);
        //padding: 5px;
        font-weight: bold;
        //align-self: center;
    } */
    
/*} */

@media screen and (max-width: 1260px){
    .course-box-left{
        display: none;
    }
    .course-box-right{
        position: absolute;
        transform: translate(-50%, -50%);
        top: 40%;
        left: 50%;
        height: 90%;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
    }
    .course-container_middle_comp{
        top: 60px;
    }
}

@media screen and (max-width: 960px){
    .course-box-right{
        width: 90%;
    }
    .course-enroll-popup-input{
        width: 70%;
    }
    .course-middle-component-submit-button{
        width: 60%;
    }
}
@media screen and (max-width: 760px){
    .course-box-right{
        height: 140%;
    }
    .course-box-right-course-feature{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .course-box-right-course-feature-left-list{
        display: flex;
        flex-direction: column;
    }
    .course-middle-circle{
        display: flex;
        flex-direction: row;
        margin-top: 30px;
        margin-bottom: 30px;
        justify-content: space-between;
        align-self: center;
    }
    
    .course-middle-circle1{
        width: 140px;
        height: 140px;
    }
    .course-middle-circle2{
        height: 120px;
        width: 120px;
        margin: -60px 0px 0px -60px;
    }
    .course-middle-circle-admit-now{
        left: -30%;
        padding: 10px;
        top: 70px;
        font-size: 15px;
    }
    .course-middle-circle-admit-now-p{
        left: -30%;
        padding: 10px;
        top: 70px;
        font-size: 15px;
    }
    
}

@media screen and (max-width: 560px){
    .course-box-right{
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        width: 90%;
    }
    .course-enroll-popup-input{
        margin-top: 30px;
        margin-left: 30px;
    }
    .course-middle-component-submit-button{
        margin-left: 40px;
    }
}